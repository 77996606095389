@charset "UTF-8";
/* CSS Document */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  word-break: normal;
  word-wrap: break-word;
}

a {
  color: #645845;
}

html {
  font-size: 20px;
  line-height: 1.7;
}

img {
  object-fit: cover;
}

@font-face {
  font-family: 'kiteOne';
  src: url(./../fonts/KiteOne-Regular.ttf);
}

.eigo {
  font-family: 'Jura', sans-serif;
}

body {
  font-family: YuGothic,"游ゴシック","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic Pro", "メイリオ","Meiryo",Osaka,Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
  background-color: #fff;
  color: #645845;
}

img {
  vertical-align: bottom;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 100%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

table {
  background-color: transparent;
}

table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

ul li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0px;
}

.fixedcontainer {
  width: 1030px;
  margin: 0 auto;
  padding: 0px;
}

a:visited,
a:link,
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
  transition: opacity 0.5s ease;
}

.red {
  color: #ed1c24;
}

.yellow {
  color: #ff6400;
}

.bkyellow {
  background-color: #fff100;
}

.bold {
  font-weight: bold;
}

.btline {
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.visible-sp {
  display: none;
}

.hidden-sp {
  display: block;
}

.visible-xs {
  display: none;
}

.hidden-xs {
  display: block;
}

.visible-500 {
  display: none;
}

.hidden-500 {
  display: block;
}

.flex_part .flex_img_left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex_part .flex_img_left > img {
  object-fit: contain;
}

.flex_part .flex_multi {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex_part .flex_body {
  flex: 1;
}

.flex_part .flex_img_right {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.flex_part .flex_img_right > img {
  object-fit: contain;
}

/* slick */
.slick-next::before {
  content: '';
  background: url(../images/slick_next.png) no-repeat;
}

.slick-prev::after {
  content: '';
  background: url(../images/slick_prev.png) no-repeat;
}

.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 50px;
  height: 50px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 100;
}

.slick-prev::after, .slick-next::before {
  font-family: initial;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 1;
}

.slick-dots {
  bottom: -60px;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 32px;
  line-height: 45px;
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  content: '•';
  text-align: center;
  opacity: 1;
  color: #c9caca;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #00a0e9;
}

.hover_anim {
  position: relative;
  overflow: hidden;
}

.hover_anim::after {
  background: #fff;
  content: "";
  height: 400px;
  left: -300px;
  opacity: .2;
  position: absolute;
  top: -80px;
  transform: rotate(35deg);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  width: 150px;
  z-index: 100;
}

.hover_anim:hover::after {
  left: 120%;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.fade-top {
  opacity: 0.1;
  transform: translate(0, 80px);
  transition: all 300ms ease-out 0s;
}

.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.custom_checkbox {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.custom_checkbox input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  appearance: none;
  left: 0;
  top: 0;
}

.custom_checkbox input[type=checkbox]:focus {
  outline: none;
}

.custom_checkbox input[type=checkbox]:checked {
  background: #036eb8;
  z-index: 0;
}

.custom_checkbox input[type=checkbox]:checked + span {
  color: white;
}

.custom_checkbox span {
  z-index: 100;
}

#header .g_nav {
  display: none;
}

/* loading
------------------------------------------------- */
#loading {
  width: 100vw;
  height: 100vh;
  background: #fff;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 9999;
}

#line {
  margin: 50vh auto 0;
  width: 100%;
  height: 1px;
  background: #ddd;
  position: relative;
}

.fullwidth .expand {
  width: 100%;
  height: 1px;
  margin: 0;
  background: #333;
  left: 0;
  top: 0;
  position: absolute;
  -webkit-animation: fullexpand 1s ease-out;
  animation: fullexpand 1s ease-out;
}

@-webkit-keyframes fullexpand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes fullexpand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.font16 {
  font-size: 0.8rem;
}

.font17 {
  font-size: 0.85rem;
}

.font18 {
  font-size: 0.9rem;
}

.font19 {
  font-size: 0.95rem;
}

.font20 {
  font-size: 1rem;
}

.font21 {
  font-size: 1.05rem;
}

.font22 {
  font-size: 1.1rem;
}

.font23 {
  font-size: 1.15rem;
}

.font24 {
  font-size: 1.2rem;
}

.font25 {
  font-size: 1.25rem;
}

.font26 {
  font-size: 1.3rem;
}

.font27 {
  font-size: 1.35rem;
}

.font28 {
  font-size: 1.4rem;
}

.font29 {
  font-size: 1.45rem;
}

.font30 {
  font-size: 1.5rem;
}

.font31 {
  font-size: 1.55rem;
}

.font32 {
  font-size: 1.6rem;
}

.font33 {
  font-size: 1.65rem;
}

.font34 {
  font-size: 1.7rem;
}

.font35 {
  font-size: 1.75rem;
}

.font36 {
  font-size: 1.8rem;
}

.font37 {
  font-size: 1.85rem;
}

.font38 {
  font-size: 1.9rem;
}

.font39 {
  font-size: 1.95rem;
}

.font40 {
  font-size: 2rem;
}

.g_nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
  display: none;
  background: black;
  height: 100%;
}

.g_nav .image_block {
  width: 60%;
  height: 100vh;
  display: inline-block;
  position: relative;
}

.g_nav .image_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.g_nav .image_block::after {
  width: 100%;
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.85);
}

.header_top {
  position: relative;
  top: 0;
  width: 100%;
  height: 125px;
}

.header_top .header1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}

.header_top .header1 h1 {
  position: relative;
  left: -85px;
}

.header_top .header1 h1 a {
  width: 300px;
}

.header_top .header1 .sns_btns {
  display: inline-flex;
  height: 100%;
  align-items: center;
}

.header_top .header1 .sns_btns a {
  margin-right: 26px;
  margin-left: 0;
  width: 20px;
}

.header_top .header1 .sns_btns a img {
  width: 100%;
}

.header_top .header2 {
  background: #f5f3ee;
}

.header_top .header2 .btn_group {
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header_top .header2 .btn_group a {
  width: 200px;
  font-size: 0.7rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #645845;
  padding: 10px 10px;
  margin-left: 0;
}

.header_top .header2 .btn_group a.active {
  border-bottom: 4px solid #ff6400;
}

.header_top .header2 .btn_group a:hover {
  background-color: #ff6400;
  color: white;
}

.header_top .header2 .btn_group .subnav {
  position: relative;
}

.header_top .header2 .btn_group .navbar a:hover, .header_top .header2 .btn_group .subnav:hover .subnavbtn {
  background-color: #ff6400;
  color: white;
}

.header_top .header2 .btn_group .subnav-content {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff6400;
  width: 200px;
  z-index: 1000;
  justify-content: space-around;
}

.header_top .header2 .btn_group .subnav-content a {
  float: left;
  color: white;
  text-decoration: none;
  margin-left: 0;
  opacity: 1;
  border: 1px solid #ff6400;
}

.header_top .header2 .btn_group .subnav-content a:hover {
  background-color: white;
  color: #ff6400;
}

.header_top .header2 .btn_group .subnav:hover .subnav-content {
  display: flex;
  flex-direction: column;
}

.header_top p {
  padding-right: 40px;
}

.header_top a {
  color: white;
  font-size: 0.8rem;
  display: inline-block;
  margin-left: 10px;
}

#drag_menu {
  position: fixed;
  top: -100px;
  left: 0;
  z-index: 101;
  width: 100%;
  text-align: center;
  height: 63px;
  background: #fff;
  border-bottom: 1px solid #e1e1e2;
}

#drag_menu .header_top {
  background: #009ca3;
}

#drag_menu .header_top a {
  color: white;
}

#drag_menu .menu_area {
  width: 100%;
  max-width: 1600px;
  min-width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  background: #0000ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#drag_menu .header_logo1 {
  display: flex;
  align-items: center;
  width: 270px;
  padding-top: 0;
  margin: 0 auto 0 0;
}

#drag_side {
  display: block;
  position: fixed;
  z-index: 101;
  right: -200px;
  top: 200px;
  width: 60px;
  max-width: 140px;
}

#drag_side #page-top {
  width: 50px;
  height: 50px;
  right: 0;
  bottom: 0;
  background: #ff6400;
  opacity: 0.6;
  border-radius: 50%;
  position: relative;
}

#drag_side #page-top::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f106';
  font-size: 25px;
  color: #fff;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  line-height: 1;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}

.drag_nav {
  float: right;
  width: 780px;
  padding: 20px 0 0;
}

.drag_nav ul {
  width: 100%;
  line-height: 1.2em;
  list-style: none;
}

.drag_nav ul > li {
  float: left;
  margin-left: 35px;
  text-align: center;
  position: relative;
}

.drag_nav .top_menu_link {
  display: inline-block;
  vertical-align: top;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 0.05em;
  font-size: 12px;
  color: #814900;
  line-height: 1.4;
}

.drag_nav .top_menu_link span {
  font-size: 16px;
  font-weight: bold;
  color: #444;
}

.drag_nav .top_menu_link:hover {
  opacity: 0.3;
}

.menu-trigger {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  margin-top: 3px;
  width: 60px;
  height: 60px;
  z-index: 2;
}

.menu-trigger:hover {
  opacity: 1;
}

.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
  cursor: pointer;
  float: right;
  position: absolute;
  width: 24px;
  height: 3px;
  left: 0;
  background: #A77E03;
  right: 0;
  margin: auto;
}

.menu-trigger span:nth-of-type(1) {
  top: 20px;
}

.menu-trigger span:nth-of-type(2) {
  top: 0;
  bottom: 0;
}

.menu-trigger span:nth-of-type(3) {
  bottom: 20px;
}

.menu-trigger.active span:nth-of-type(1) {
  transform: translateY(8.5px) rotate(-45deg);
}

.menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}

.menu-trigger.active span:nth-of-type(3) {
  transform: translateY(-8.5px) rotate(45deg);
}

.g_nav ul {
  width: 39%;
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  letter-spacing: 0.1em;
  padding-bottom: 5vw;
}

.g_nav ul li {
  float: none;
  width: 100%;
  font-size: 16px;
  margin: 10px 0;
  text-align: center;
}

.g_nav ul li a {
  display: block;
  padding: 1.2em 0;
  color: #fff;
  text-decoration: none;
}

.g_nav ul li a:hover {
  opacity: 0.9;
  color: #66aff1;
  background: #fff;
}

#top_sp {
  margin-top: 66px;
}

.top {
  width: 100%;
  position: relative;
  height: 740px;
}

.top .container {
  text-align: center;
}

.top .top_ttl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.top .top_txt1 {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
}

.top .top_txt1 img {
  width: 100%;
}

/*-------------header----------------------*/
/*-----------        -----------------*/
h2 {
  text-align: center;
}

h2 .nihongo {
  font-size: 1.8rem;
  font-weight: bold;
  display: block;
  line-height: 2;
  letter-spacing: 0.5px;
}

h2 .eigo {
  font-size: 0.7rem;
  display: block;
  letter-spacing: 1px;
}

#sec_fv {
  height: 32vw;
  max-width: 100%;
  margin: auto;
  background: url(./../images/fv.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#sec_txt_fv {
  height: 17.98vw;
  max-width: 100%;
  margin: auto;
  background: url(./../images/fv_txt.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#sec1 > p {
  padding-top: 100px;
  font-size: 0.8rem;
  line-height: 1.7;
  text-align: center;
}

#sec1 h2 {
  padding-top: 100px;
  padding-bottom: 74px;
}

#sec1 .part1 {
  padding-bottom: 100px;
}

#sec1 .part1 .block1 .flex_part {
  padding: 0 18px;
}

#sec1 .part1 .block1 .flex_part > img {
  width: 315px;
  height: 219px;
  object-fit: cover;
  max-width: initial;
}

#sec1 .part1 .block1 .flex_part h3 {
  font-size: 0.8rem;
  font-weight: bold;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

#sec1 .part1 .block1 .flex_part p {
  font-size: 0.7rem;
}

#sec1 .part1 .block1 .flex_part .readmore_btn {
  text-align: right;
  padding-top: 25px;
  font-family: 'Jura', sans-serif;
  font-size: 0.6rem;
  margin-right: calc(100% - 315px);
  text-decoration: underline;
}

#sec1 .part1 .block1 .slick-prev, #sec1 .part1 .block1 .slick-next {
  top: 80px;
  transform: none;
}

#sec1 .part1 .block1 .slick-prev {
  left: 100px;
  width: 64px;
  height: 65px;
  background: url(./../images/arrow_l.png);
}

#sec1 .part1 .block1 .slick-prev::after {
  content: none;
}

#sec1 .part1 .block1 .slick-prev::before {
  content: none;
}

#sec1 .part1 .block1 .slick-next {
  right: 100px;
  width: 64px;
  height: 65px;
  background: url(./../images/arrow_r.png);
}

#sec1 .part1 .block1 .slick-next::after {
  content: none;
}

#sec1 .part1 .block1 .slick-next::before {
  content: none;
}

#sec1 .part1 .block2 {
  padding-top: 40px;
  text-align: center;
}

#sec1 .part1 .block2 a {
  display: inline-block;
}

#sec2 {
  background: #f5f3ee;
}

#sec2 h2 {
  padding-top: 95px;
  padding-bottom: 80px;
}

#sec2 .part1 {
  padding-bottom: 100px;
}

#sec2 .part1 #tabs {
  border: none;
  background: none;
  font-family: YuGothic,"游ゴシック","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic Pro", "メイリオ","Meiryo",Osaka,Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
}

#sec2 .part1 #tabs ul {
  display: flex;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 2px solid #ff6400;
}

#sec2 .part1 #tabs ul li {
  flex: 1;
  height: 65px;
  margin-right: 8px;
  background: white;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#sec2 .part1 #tabs ul li:last-child {
  margin-right: 0;
}

#sec2 .part1 #tabs ul li.ui-state-active {
  background: #ff6400;
  border: none;
}

#sec2 .part1 #tabs ul li.ui-state-active a {
  color: white;
}

#sec2 .part1 #tabs ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  font-size: 0.75rem;
  color: #ff6400;
  width: 100%;
  height: 100%;
}

#sec2 .part1 #tabs .content {
  margin-top: -70px;
  padding: 0;
  padding-top: 70px;
}

#sec2 .part1 #tabs .content > div {
  padding: 40px 50px 80px;
  background: white;
}

#sec2 .part1 #tabs .content p {
  font-size: 0.8rem;
  color: #645845;
}

#sec2 .part1 #tabs .content .img_group {
  display: flex;
  justify-content: space-evenly;
  padding-top: 50px;
  padding-bottom: 50px;
}

#sec2 .part1 #tabs .content .img_group img {
  width: 360px;
  height: 250px;
  object-fit: cover;
}

#sec2 .part1 #tabs .content .img_group img.contain_img {
  object-fit: contain;
}

#sec2 .part1 #tabs .content .btn_block {
  text-align: center;
}

#sec2 .part1 #tabs .content .btn_block a {
  display: inline-block;
}

#sec3 h2 {
  padding-top: 100px;
  padding-bottom: 70px;
}

#sec3 .part1 .block .flex_body {
  padding-right: 30px;
}

#sec3 .part1 .block .flex_body h3 {
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 30px;
}

#sec3 .part1 .block .flex_body p {
  font-size: 0.8rem;
}

#sec3 .part2 {
  padding-bottom: 100px;
}

#sec3 .part2 h3 {
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 90px;
}

#sec3 .part2 h3::before {
  content: "";
  border-radius: 50%;
  width: 24px;
  height: 24px;
  border: 6px solid #ff6400;
  display: inline-block;
}

#sec3 .part2 h3 .nihongo {
  padding-left: 16px;
  font-size: 1.2rem;
  font-weight: bold;
}

#sec3 .part2 h3 .eigo {
  font-size: 0.7rem;
  font-weight: bold;
}

#sec3 .part2 h3 .slash {
  margin: 0 30px;
}

#sec3 .part2 .part_body {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

#sec3 .part2 .block {
  flex: 1;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  align-items: center;
  justify-content: flex-start;
}

#sec3 .part2 .block h4 {
  height: 110px;
  text-align: center;
  font-size: 0.7rem;
  margin-top: 28px;
}

#sec3 .part2 .block h4 .name {
  font-size: 0.9rem;
}

#sec3 .part2 .block p {
  text-align: left;
  font-size: 0.7rem;
}

#sec3 .part2 .btn_part {
  text-align: center;
  margin-top: 45px;
}

#sec3 .part2 .btn_part a {
  display: inline-block;
}

#sec4 {
  background: #f5f3ee;
  padding-bottom: 60px;
}

#sec4 h2 {
  padding-top: 100px;
  padding-bottom: 80px;
}

#sec4 .part {
  background: white;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 40px;
}

#sec4 .part h3 {
  text-align: center;
}

#sec4 .part h3 span {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0 5px;
  border-bottom: 2px solid #ff6400;
}

#sec4 .part .block {
  padding-top: 50px;
}

#sec4 .part .block .slick-next::after, #sec4 .part .block .slick-next::before, #sec4 .part .block .slick-prev::after, #sec4 .part .block .slick-prev::before {
  content: none;
}

#sec4 .part .block .slick-next {
  background: url(./../images/arrow_r.png) no-repeat;
  background-size: contain;
}

#sec4 .part .block .slick-prev {
  background: url(./../images/arrow_l.png) no-repeat;
  background-size: contain;
}

#sec4 .part .block .slicker_part {
  padding: 0 20px;
}

#sec4 .part .block .slicker_part h4 {
  text-align: center;
  padding-top: 0;
  padding-bottom: 15px;
}

#sec4 .part .block .slicker_part img {
  width: 304px;
  height: 205px;
  object-fit: cover;
}

#sec4 .part .block .slicker_part > h5 {
  height: auto;
  text-align: center;
  margin-top: 20px;
}

#sec4 .part .block .slicker_part > h5 img {
  height: auto;
  width: 300px;
  object-fit: contain;
  margin: auto;
}

#sec4 .part .block .slicker_part > h6 {
  font-size: 0.8rem;
  font-weight: bold;
  padding: 30px 0 5px;
  text-align: center;
  height: 85px;
}

#sec4 .part .block .slicker_part > h6 span {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 60%, #ff6600 60%);
  padding: 0 5px;
}

#sec4 .part .block .slicker_part > p {
  font-size: 0.7rem;
  height: 120px;
  padding: 10px 0;
}

#sec4 .part .block .slicker_part .content {
  display: none;
}

#sec4 .part .block .slicker_part .content h5 {
  height: auto;
  text-align: center;
}

#sec4 .part .block .slicker_part .content h5 img {
  height: auto;
  width: 300px;
  object-fit: contain;
}

#sec4 .part .block .slicker_part .content h6 {
  font-size: 0.8rem;
  font-weight: bold;
  padding: 30px 0 5px;
  text-align: center;
}

#sec4 .part .block .slicker_part .content h6 span {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 60%, #ff6600 60%);
  padding: 0 5px;
}

#sec4 .part .block .slicker_part .content p {
  font-size: 0.7rem;
  padding: 10px 0;
}

#sec4 .part .block .slicker_part a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6400;
  text-align: center;
}

#sec4 .part .block .slicker_part a.hidden {
  display: none;
}

#sec4 .part .block .slicker_part a span {
  color: white;
  font-size: 0.7rem;
  line-height: 1;
  padding: 10px 0;
  display: inline-block;
}

#sec4 .part .block .slicker_part a span::before {
  color: #ff6400;
  font-weight: bold;
  background: white;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

#sec4 .part .block .slicker_part a.voice_readmore {
  margin-top: 20px;
}

#sec4 .part .block .slicker_part a.voice_readmore span::before {
  content: "↓";
}

#sec4 .part .block .slicker_part a.voice_readless {
  margin-top: 20px;
}

#sec4 .part .block .slicker_part a.voice_readless span::before {
  content: "↑";
}

#sec5 h2 {
  padding-top: 100px;
  padding-bottom: 30px;
}

#sec5 > p {
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 80px;
}

#sec5 .part1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

#sec5 .part1 .block {
  margin: 20px 0;
  width: 188px;
  margin-right: 22.5px;
}

#sec5 .part1 .block:nth-child(5n) {
  margin-right: 0;
}

#sec5 .part1 .block > img {
  width: 100%;
  height: 130px;
  object-fit: contain;
}

#sec5 .part1 .block h3 {
  font-size: 0.6rem;
  font-weight: bold;
  height: 35px;
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
}

#sec5 .part1 .block p {
  font-size: 0.5rem;
  line-height: 1.7;
}

#sec5 .part2 {
  margin-top: 60px;
  padding-bottom: 100px;
  text-align: center;
}

#sec5 .part2 a {
  display: inline-block;
}

#sec6 {
  background: #f5f3ee;
}

#sec6 h2 {
  padding-top: 100px;
  padding-bottom: 70px;
}

#sec6 .part1 {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}

#sec6 .part1 .block {
  background: white;
  padding: 33px 34px;
  width: 320px;
  margin-bottom: 35px;
  margin-right: 35px;
  border-radius: 10px;
}

#sec6 .part1 .block:nth-child(3n) {
  margin-right: 0;
}

#sec6 .part1 .block .block_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#sec6 .part1 .block .block_header span {
  display: inline-block;
}

#sec6 .part1 .block .block_header span:first-child {
  font-size: 0.75rem;
  padding: 0 4px;
  color: white;
}

#sec6 .part1 .block .block_header span:first-child.news_category {
  background: #ff6400;
}

#sec6 .part1 .block .block_header span:first-child.event_category {
  background: #21dc21;
}

#sec6 .part1 .block .block_header span:first-child.media_category {
  background: #3866aa;
}

#sec6 .part1 .block .block_header span:last-child {
  font-size: 0.6rem;
}

#sec6 .part1 .block .block_header .news_category {
  font-size: 0.75rem;
}

#sec6 .part1 .block h3 {
  font-size: 0.8rem;
  padding: 25px 0;
}

#sec6 .part1 .block p {
  font-size: 0.6rem;
}

#sec6 .part1 .block .block_footer {
  padding-top: 35px;
}

#sec6 .part1 .block .block_footer span {
  font-size: 0.6rem;
}

#sec6 .part1 .block .readmore_btn {
  text-align: left;
  padding-top: 25px;
  font-family: 'Jura', sans-serif;
  font-size: 0.6rem;
  text-decoration: underline;
}

#sec6 .part2 {
  padding-bottom: 100px;
  padding-top: 40px;
  text-align: center;
}

#sec6 .part2 a {
  display: inline-block;
}

#sec6 .part3 {
  max-width: 1200px;
  margin: auto;
  border-top: 2px solid white;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#sec6 .part3 a {
  display: inline-block;
  max-width: 213px;
  max-height: 115px;
  margin-right: 32px;
  border-radius: 10px;
  overflow: hidden;
}

#sec6 .part3 a:nth-child(5n) {
  margin-right: 0;
}

#sec6 .part3 a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#product_sec_fv {
  height: 17.2vw;
  background: url(./../images/product_fv.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#product_sec_fv h2 {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#brand_sec_fv {
  height: 17.2vw;
  background: url(./../images/brand_fv.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#brand_sec_fv h2 {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#ec_sec_fv {
  height: 17.2vw;
  background: url(./../images/ec_fv.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#ec_sec_fv h2 {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 15px 40px 10px;
}

#school_sec_fv {
  height: 17.2vw;
  background: url(./../images/school_fv.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#school_sec_fv h2 {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 15px 40px 10px;
}

#local_sec_fv {
  height: 17.2vw;
  background: url(./../images/local_fv.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#local_sec_fv h2 {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#services_sec1 {
  background: #f5f3ee;
  padding-top: 40px;
  padding-bottom: 80px;
}

#services_sec1 .part1 {
  display: flex;
  border-radius: 10px;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  padding: 40px;
  flex-wrap: wrap;
}

#services_sec1 .part1 h2 {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  font-size: 0.9rem;
  align-items: center;
}

#services_sec1 .part1 h2 span {
  margin-left: 10px;
}

#services_sec1 .part1 .block {
  width: 169px;
  height: 90px;
  background: #f5f3ee;
  border-radius: 10px;
}

#services_sec1 .part1 .block.active {
  background: #ff6400;
}

#services_sec1 .part1 .block.active a {
  color: white;
}

#services_sec1 .part1 .block a {
  font-weight: bold;
  font-size: 0.8rem;
  display: inline-block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.7;
  color: #645845;
}

#services_sec2 {
  padding-bottom: 100px;
}

#services_sec2 h2 {
  padding-top: 100px;
  padding-bottom: 40px;
}

#services_sec2 .circle_mark {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border: 6px solid #ff6400;
  border-radius: 50%;
}

#services_sec2 > p {
  font-size: 0.75rem;
  line-height: 1.7;
  text-align: center;
}

#services_sec2 .part1 {
  background: #f5f3ee;
  padding-bottom: 160px;
  margin-top: 100px;
}

#services_sec2 .part1 h3 {
  font-size: 1.4rem;
  color: #ff6400;
  text-align: center;
  padding-top: 95px;
  padding-bottom: 53px;
}

#services_sec2 .part1 .block1 {
  background: white;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

#services_sec2 .part1 .block1 h4 {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 40px;
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

#services_sec2 .part1 .block1 h4 span {
  display: inline-block;
  font-weight: bold;
  font-size: 2.5rem;
}

#services_sec2 .part1 .block1 h4 span.r-right {
  transform: rotate(-30deg);
}

#services_sec2 .part1 .block1 h4 span.r-left {
  transform: rotate(30deg);
}

#services_sec2 .part1 .block1 ul li {
  font-size: 1.05rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 13px;
}

#services_sec2 .part1 .block1 ul li span {
  margin-left: 20px;
}

#services_sec2 .part1 .block2 {
  padding-top: 100px;
}

#services_sec2 .part1 .block2 h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 32px;
}

#services_sec2 .part1 .block2 .block_body {
  background: white;
  padding: 78px 52px;
  border-radius: 10px;
}

#services_sec2 .part1 .block2 .block_body .slick-next::after, #services_sec2 .part1 .block2 .block_body .slick-next::before, #services_sec2 .part1 .block2 .block_body .slick-prev::after, #services_sec2 .part1 .block2 .block_body .slick-prev::before {
  content: none;
}

#services_sec2 .part1 .block2 .block_body .slick-next {
  background: url(./../images/arrow_r.png) no-repeat;
  background-size: contain;
  right: -145px;
}

#services_sec2 .part1 .block2 .block_body .slick-prev {
  background: url(./../images/arrow_l.png) no-repeat;
  background-size: contain;
  left: -145px;
}

#services_sec2 .part1 .block2 .block_body .slicker_part h5 {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  padding-bottom: 75px;
}

#services_sec2 .part1 .block2 .block_body .slicker_part a {
  color: #66aff1;
}

#services_sec2 .part1 .block2 .block_body .slicker_part .piece .img_block > img {
  margin-bottom: 28px;
  border-radius: 20px;
}

#services_sec2 .part1 .block2 .block_body .slicker_part .piece .flex_body {
  font-size: 0.75rem;
  line-height: 1.9;
  padding-left: 50px;
}

#services_sec2 .part1 .block2 .block_body .slicker_part .piece .flex_body p {
  margin-bottom: 10px;
}

#services_sec2 .part1 .block2 .block_body .slicker_part .piece .flex_body dl {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
}

#services_sec2 .part1 .block2 .block_body .slicker_part .piece .flex_body dl dt {
  width: 77px;
  font-size: 0.65rem;
}

#services_sec2 .part1 .block2 .block_body .slicker_part .piece .flex_body dl dd {
  line-height: 1.7;
  font-size: 0.65rem;
}

#services_sec2 .part1 .block2 .block_body .slick-dots {
  bottom: -168px;
}

#services_sec2 .part1 .block2 .block_body .slick-dots li {
  height: 6px;
}

#services_sec2 .part1 .block2 .block_body .slick-dots li button {
  width: 40px;
  height: 6px;
  border-radius: 6px;
  background: #a29888;
}

#services_sec2 .part1 .block2 .block_body .slick-dots li button::after, #services_sec2 .part1 .block2 .block_body .slick-dots li button::before {
  content: none;
}

#services_sec2 .part1 .block2 .block_body .slick-dots li.slick-active button {
  background: #ff6400;
}

#services_sec2 .part2 .block3 {
  padding-top: 100px;
}

#services_sec2 .part2 .block3 h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 50px;
}

#services_sec2 .part2 .block3 > p {
  font-size: 0.75rem;
}

#services_sec2 .part2 .block3 .block_body ul li {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  line-height: 1.7;
}

#services_sec2 .part2 .block3 .block_body ul li .feature {
  margin-right: 15px;
  display: inline-block;
  padding: 10px 35px;
  background: #ff6400;
  color: white;
  border-radius: 50px;
  font-weight: bold;
}

#services_sec2 .part2 .block4 {
  padding-top: 100px;
}

#services_sec2 .part2 .block4 h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 10px;
}

#services_sec2 .part2 .block4 > p {
  font-size: 0.75rem;
  line-height: 1.7;
}

#services_sec2 .part2 .block4 .block_body {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#services_sec2 .part2 .block4 .block_body .piece {
  max-width: 237px;
  margin-right: 20px;
  border: 2px solid #ff6400;
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
}

#services_sec2 .part2 .block4 .block_body .piece img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#services_sec2 .part2 .block4 .block_body .piece .step {
  position: absolute;
  background: #ff6400;
  top: 25px;
  left: -5px;
  color: white;
  z-index: 100;
  width: 55px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  height: 26px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#services_sec2 .part2 .block4 .block_body .piece .step::after {
  border-top: 13px solid transparent;
  border-left: 10px solid #ff6400;
  border-bottom: 13px solid transparent;
  content: "";
  position: absolute;
  right: -10px;
}

#services_sec2 .part2 .block4 .block_body .piece2 {
  max-width: 502px;
  margin-right: 0;
}

#services_sec2 .part2 .block4 .block_body .piece .piece_body {
  padding: 20px;
}

#services_sec2 .part2 .block4 .block_body .piece .piece_body h5 {
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  color: #ff6400;
  margin-bottom: 10px;
}

#services_sec2 .part2 .block4 .block_body .piece .piece_body p {
  font-size: 0.65rem;
  display: flex;
}

#services_sec2 .part2 .block4 .block_body .piece .piece_body .piece_group {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#services_sec2 .part2 .block4 .block_body .piece .piece_body .piece_group ul {
  flex: 1;
}

#services_sec2 .part2 .block4 .block_body .piece .piece_body .piece_group ul li {
  font-size: 0.65rem;
}

#services_sec2 .part2 .block5 {
  padding-top: 60px;
}

#services_sec2 .part2 .block5 h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 10px;
}

#services_sec2 .part2 .block5 .block_body {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#services_sec2 .part2 .block5 .block_body .piece {
  margin-right: 26px;
  background: #f5f3ee;
  margin-top: 30px;
  display: inline-flex;
  width: 176px;
  height: 176px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 5px solid #ff6400;
  font-size: 1.05rem;
  font-weight: bold;
  text-align: center;
  color: #ff6400;
}

#services_sec2 .part2 .block5 .block_body ul li {
  font-size: 0.8rem;
  line-height: 1.7;
  padding: 12px 0;
  border-bottom: 1px dotted #645845;
}

#services_sec2 .part3 {
  background: #f5f3ee;
  padding-bottom: 100px;
  margin-top: 100px;
  padding-top: 100px;
}

#services_sec2 .part3 .block1 {
  background: white;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 40px;
}

#services_sec2 .part3 .block1 h4 {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 40px;
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

#services_sec2 .part3 .block1 h4 span {
  display: inline-block;
  font-weight: bold;
  font-size: 2.5rem;
}

#services_sec2 .part3 .block1 h4 span.r-right {
  transform: rotate(-30deg);
}

#services_sec2 .part3 .block1 h4 span.r-left {
  transform: rotate(30deg);
}

#services_sec2 .part3 .block1 ul li {
  font-size: 1.05rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 13px;
}

#services_sec2 .part3 .block1 ul li span {
  margin-left: 20px;
}

#services_sec2 .part3 .block6 {
  padding-bottom: 100px;
}

#services_sec2 .part3 .block6 h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 25px;
}

#services_sec2 .part3 .block6 .block_body {
  background: white;
  padding: 40px;
  border-radius: 10px;
}

#services_sec2 .part3 .block6 .block_body ul li {
  font-size: 1.05rem;
  line-height: 1.8;
}

#services_sec2 .part3 .block7 h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 25px;
}

#services_sec2 .part3 .block7 .block_body {
  background: white;
  padding: 40px;
  border-radius: 10px;
}

#services_sec2 .part3 .block7 .block_body h5 {
  color: white;
  width: 100%;
  border-radius: 50px;
  background-color: #ff6400;
  font-size: 1.05rem;
  font-weight: bold;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 35px;
}

#services_sec2 .part3 .block7 .block_body h5.second {
  background-color: #645845;
}

#services_sec2 .part3 .block7 .block_body dl {
  margin-bottom: 40px;
}

#services_sec2 .part3 .block7 .block_body dl dt {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

#services_sec2 .part3 .block7 .block_body dl dt span {
  margin-left: 18px;
  display: inline-block;
  font-size: 1.05rem;
  font-weight: bold;
  color: #ff6400;
}

#services_sec2 .part3 .block7 .block_body dl dd {
  padding-left: 55px;
  font-size: 0.8rem;
  padding-bottom: 5px;
}

#services_sec2 .part3 .block7 .block_body ul {
  padding-left: 40px;
}

#services_sec2 .part3 .block7 .block_body ul li {
  list-style: "・";
  font-size: 1.05rem;
}

#services_sec2 .part3 .block8 h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 25px;
}

#services_sec2 .part3 .block8 .block_body {
  background: white;
  padding: 40px 40px;
}

#services_sec2 .part3 .block8 .block_body > p {
  font-size: 1.05rem;
}

#services_sec2 .part3 .block8 .block_body ul {
  padding: 40px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}

#services_sec2 .part3 .block8 .block_body ul li {
  display: block;
  max-width: 400px;
  margin-bottom: 30px;
}

#services_sec2 .part3 .block8 .block_body ul li .img_wrapper {
  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;
}

#services_sec2 .part3 .block8 .block_body ul li .img_wrapper img {
  width: 400px;
  height: 270px;
  object-fit: contain;
  object-position: center;
}

#services_sec2 .part3 .block8 .block_body ul li > p {
  font-size: 0.75rem;
  text-align: center;
}

#services_sec2 .part3 .block8 .block_body ul li:last-child {
  margin-bottom: 0;
}

#services_sec3 {
  background: #f5f3ee;
  padding: 100px 0;
}

#services_sec3 .circle_mark {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-right: 10px;
  border: 6px solid #ff6400;
  border-radius: 50%;
}

#services_sec3 .part1 {
  padding: 75px 85px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
}

#services_sec3 .part1 small {
  line-height: 1.7;
}

#services_sec3 .part1 .block {
  width: 50%;
  border-bottom: 1px dotted black;
  padding: 45px 20px 45px;
}

#services_sec3 .part1 .block:nth-child(odd) {
  border-right: 1px dotted black;
}

#services_sec3 .part1 .block2 {
  width: 100%;
  margin-bottom: 25px;
  border-bottom: none;
  padding-bottom: 0;
  border-right: none !important;
}

#services_sec3 .part1 .block2 p {
  font-weight: normal !important;
  line-height: 1.7;
}

#services_sec3 .part1 .block3 {
  border-top: 2px solid #645845;
  width: 100%;
  padding-top: 25px;
  border-bottom: none;
  padding-bottom: 0;
  border-right: none !important;
}

#services_sec3 .part1 .block3 p {
  font-weight: normal !important;
  line-height: 1.7;
}

#services_sec3 .part1 .block h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 1.05rem;
  margin-bottom: 30px;
}

#services_sec3 .part1 .block h4 img {
  margin-right: 20px;
}

#services_sec3 .part1 .block .block_body > p {
  font-size: 0.8rem;
}

#services_sec3 .part1 .block .block_body > p.flex_body {
  display: block;
}

#services_sec3 .part1 .block .block_body h5 {
  font-size: 0.8rem;
  font-weight: normal;
}

#services_sec3 .part1 .block .block_body small {
  font-size: 0.6rem;
  line-height: 1.4;
}

#services_sec3 .part1 .block .block_body ul li {
  font-size: 0.8rem;
}

#services_sec3 .part1 .block .block_body.flex_multi .flex_body {
  width: 50%;
  padding: 20px 20px 20px;
}

#services_sec3 .part1 .block .block_body.flex_multi .flex_body:nth-child(odd) {
  border-right: 1px dotted black;
}

#services_sec3 .part2 .voice_block {
  padding-top: 100px;
}

#services_sec3 .part2 .voice_block h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  padding-bottom: 32px;
}

#services_sec3 .part2 .voice_block .block_body {
  background: white;
  padding: 78px 78px;
  border-radius: 10px;
}

#services_sec3 .part2 .voice_block .block_body .slick-next::after, #services_sec3 .part2 .voice_block .block_body .slick-next::before, #services_sec3 .part2 .voice_block .block_body .slick-prev::after, #services_sec3 .part2 .voice_block .block_body .slick-prev::before {
  content: none;
}

#services_sec3 .part2 .voice_block .block_body .slick-next {
  background: url(./../images/arrow_r.png) no-repeat;
  background-size: contain;
  right: -145px;
}

#services_sec3 .part2 .voice_block .block_body .slick-prev {
  background: url(./../images/arrow_l.png) no-repeat;
  background-size: contain;
  left: -145px;
}

#services_sec3 .part2 .voice_block .block_body .slicker_part h5 {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  padding-bottom: 75px;
}

#services_sec3 .part2 .voice_block .block_body .slicker_part .img_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 55px;
}

#services_sec3 .part2 .voice_block .block_body .slicker_part .img_block img {
  border-radius: 10px;
}

#services_sec3 .part2 .voice_block .block_body .slicker_part .piece {
  padding: 50px 0;
  border-bottom: 1px dotted #645845;
}

#services_sec3 .part2 .voice_block .block_body .slicker_part .piece .flex_body {
  font-size: 0.75rem;
  line-height: 1.9;
}

#services_sec3 .part2 .voice_block .block_body .slicker_part .piece .flex_body h6 {
  font-size: 0.9rem;
  font-weight: bold;
}

#services_sec3 .part2 .voice_block .block_body .slicker_part .piece.flex_img_left .flex_body {
  padding-left: 50px;
}

#services_sec3 .part2 .voice_block .block_body .slicker_part .piece.flex_img_right .flex_body {
  padding-right: 50px;
}

#services_sec3 .part2 .voice_block .block_body .slick-dots {
  bottom: -168px;
}

#services_sec3 .part2 .voice_block .block_body .slick-dots li {
  height: 6px;
}

#services_sec3 .part2 .voice_block .block_body .slick-dots li button {
  width: 40px;
  height: 6px;
  border-radius: 6px;
  background: #a29888;
}

#services_sec3 .part2 .voice_block .block_body .slick-dots li button::after, #services_sec3 .part2 .voice_block .block_body .slick-dots li button::before {
  content: none;
}

#services_sec3 .part2 .voice_block .block_body .slick-dots li.slick-active button {
  background: #ff6400;
}

#services_brand_list .part1 {
  padding: 100px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  background: white;
  flex-wrap: wrap;
}

#services_brand_list .part1 h2 {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  font-size: 0.9rem;
  align-items: center;
}

#services_brand_list .part1 h2 span {
  margin-left: 10px;
}

#services_brand_list .part1 .block {
  width: 169px;
  height: 90px;
  background: #f5f3ee;
  border-radius: 10px;
}

#services_brand_list .part1 .block.active {
  background: #ff6400;
}

#services_brand_list .part1 .block.active a {
  color: white;
}

#services_brand_list .part1 .block a {
  font-weight: bold;
  font-size: 0.8rem;
  display: inline-block;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.7;
  color: #645845;
}

footer .part1 {
  background: #ff6400;
}

footer .part1 h2 {
  padding-top: 85px;
  padding-bottom: 45px;
  color: white;
  font-size: 1.4rem;
}

footer .part1 .block {
  padding-bottom: 90px;
}

footer .part1 .block a {
  display: inline-block;
  margin-bottom: 20px;
}

footer .part1 .block p {
  font-size: 0.7rem;
  color: white;
}

footer .part2 h3 {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 60px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .part2 h3::before {
  content: "";
  height: 1.4rem;
  width: 3px;
  background: #645845;
  margin-right: 20px;
  transform: rotate(-30deg);
}

footer .part2 h3::after {
  content: "";
  height: 1.4rem;
  width: 3px;
  background: #645845;
  margin-left: 20px;
  transform: rotate(30deg);
}

footer .part2 .block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}

footer .part2 .block a {
  display: block;
  margin: 0 10px;
  color: #645845;
  width: 32px;
}

footer .part3 {
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  padding-bottom: 100px;
  max-width: 1200px;
  margin: auto;
  border-bottom: 2px solid #645845;
}

footer .part3 .block {
  flex: 1;
  padding: 0 10px;
  border-right: 2px solid #f5f3ee;
}

footer .part3 .block:last-child {
  border-right: none;
}

footer .part3 .block h3 {
  font-size: 0.7rem;
  font-weight: bold;
  margin-bottom: 20px;
}

footer .part3 .block ul li {
  font-size: 0.7rem;
  padding: 5px 0;
}

footer .part3 .block ul li a {
  font-weight: bold;
  color: #645845;
}

footer .part3 .block ul li a span {
  display: inline-block;
  width: 80%;
  vertical-align: top;
}

footer .part4 p {
  font-size: 0.6rem;
  padding: 40px 0;
}

#partner_sec_fv {
  height: 308px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-color: #cfcbc5;
}

#partner_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#partner_sec1 {
  background-color: #f5f3ee;
}

#partner_sec1 h2 {
  padding-top: 100px;
  padding-bottom: 20px;
}

#partner_sec1 > p {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 60px;
}

#partner_sec1 .part1 {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 50px;
}

#partner_sec1 .part1 .block {
  background: white;
  padding: 50px 30px 60px;
  margin-bottom: 20px;
  max-width: 241px;
  margin-right: 22px;
  border-radius: 10px;
  position: relative;
}

#partner_sec1 .part1 .block:nth-child(4n) {
  margin-right: 0;
}

#partner_sec1 .part1 .block img {
  width: 189px;
  height: 130px;
  object-fit: contain;
  object-position: center;
}

#partner_sec1 .part1 .block h3 {
  padding-top: 40px;
  font-size: 0.7rem;
  text-align: center;
  padding-bottom: 14px;
}

#partner_sec1 .part1 .block p.description {
  font-size: 0.6rem;
  font-weight: bold;
}

#partner_sec1 .part1 .block p.project {
  padding-top: 0.9rem;
  font-size: 0.5rem;
}

#partner_sec1 .part1 .block .btn_group {
  text-align: right;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

#partner_sec1 .part1 .block .btn_group a {
  font-size: 0.7rem;
  color: black;
}

#partner_sec1 .part1 .block .btn_group a {
  font-size: 0.5rem;
  color: black;
}

#news_sec_fv {
  height: 308px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-color: #cfcbc5;
}

#news_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#news_sec1 {
  background-color: #f5f3ee;
  padding-top: 100px;
  padding-bottom: 100px;
}

#news_sec1 .part1 {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#news_sec1 .part1 .block {
  background: white;
  padding: 30px;
  margin-bottom: 32px;
  width: 320px;
  margin-right: 22px;
  border-radius: 10px;
}

#news_sec1 .part1 .block:nth-child(3n) {
  margin-right: 0;
}

#news_sec1 .part1 .block .block_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#news_sec1 .part1 .block .block_header span {
  display: inline-block;
}

#news_sec1 .part1 .block .block_header span.category {
  font-size: 0.75rem;
  padding: 0 4px;
  color: white;
}

#news_sec1 .part1 .block .block_header span.category.NEWS_category {
  background: #ff6400;
}

#news_sec1 .part1 .block .block_header span.category.EVENT_category {
  background: #21dc21;
}

#news_sec1 .part1 .block .block_header span.category.MEDIA_category {
  background: #3866aa;
}

#news_sec1 .part1 .block .block_header span:last-child {
  font-size: 0.6rem;
}

#news_sec1 .part1 .block .block_header .news_category {
  font-size: 0.75rem;
}

#news_sec1 .part1 .block h3 {
  font-size: 0.8rem;
  padding: 25px 0;
}

#news_sec1 .part1 .block p {
  font-size: 0.6rem;
}

#news_sec1 .part1 .block .block_footer {
  padding-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#news_sec1 .part1 .block .block_footer .tag_block {
  flex: 1;
}

#news_sec1 .part1 .block .block_footer .tag_block span {
  font-size: 0.6rem;
}

#news_sec1 .part1 .block .block_footer .new_mark {
  margin-left: 20px;
  color: #ff6400;
  border: 1px solid #ff6400;
  border-radius: 50%;
  display: inline-flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}

#misomaga_sec_fv {
  height: 17.2vw;
  background: url(./../images/misomaga_fv.png);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#misomaga_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#works_sec_fv {
  height: 17.2vw;
  background: url(./../images/works_fv.png);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

#works_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#misomaga_sec1 {
  background: #f5f3ee;
  padding-bottom: 100px;
}

#misomaga_sec1 .fixedcontainer {
  background: white;
  border-radius: 10px;
  padding: 36px 40px;
}

#misomaga_sec1 .part1 {
  padding-bottom: 70px;
}

#misomaga_sec1 .part1 h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}

#misomaga_sec1 .part1 h3 span {
  margin-left: 8px;
  font-size: 0.8rem;
}

#misomaga_sec1 .part1 .part_block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#misomaga_sec1 .part1 .block {
  margin-right: 38px;
  width: 285px;
}

#misomaga_sec1 .part1 .block:nth-child(3n) {
  margin-right: 0;
}

#misomaga_sec1 .part1 .block > img {
  width: 285px;
  height: 190px;
  object-fit: v;
}

#misomaga_sec1 .part1 .block h4 {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: left;
  margin-top: 10px;
}

#misomaga_sec1 .part2 h3 {
  font-size: 0.8rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 25px;
}

#misomaga_sec1 .part2 .part_block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#misomaga_sec1 .part2 .part_block a {
  font-size: 0.8rem;
  font-weight: bold;
  color: #ff6400;
  display: inline-block;
  padding: 5px 20px;
  border: 1px solid #ff6400;
  border-radius: 15px;
  line-height: 1;
  margin-right: 20px;
  margin-bottom: 20px;
}

#misomaga_sec2 {
  background: #f5f3ee;
  padding-top: 75px;
}

#misomaga_sec2 .part1 {
  margin-bottom: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

#misomaga_sec2 .part1 a {
  display: flex;
  flex: 1;
  font-size: 0.9rem;
  height: 65px;
  align-items: center;
  justify-content: center;
  background: white;
  color: #ff6400;
  line-height: 1;
  font-weight: bold;
}

#misomaga_sec2 .part1 a.active {
  background: #ff6400;
  color: white;
  font-weight: bold;
  line-height: 1;
}

#misomaga_sec2 .part2 {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 30px;
}

#misomaga_sec2 .part2 .block {
  background: white;
  width: 320px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 35px;
  margin-right: 35px;
}

#misomaga_sec2 .part2 .block:nth-child(3n) {
  margin-right: 0;
}

#misomaga_sec2 .part2 .block > img {
  width: 320px;
  height: 235px;
  object-fit: contain;
}

#misomaga_sec2 .part2 .block .block_body {
  background: white;
  padding: 23px 30px;
}

#misomaga_sec2 .part2 .block .block_body h3 {
  font-size: 1rem;
  font-weight: bold;
}

#misomaga_sec2 .part2 .block .block_body .block_header {
  text-align: left;
}

#misomaga_sec2 .part2 .block .block_body .block_header span {
  display: inline-block;
  padding: 5px 12px;
  border: 1px solid #ff6400;
  color: #ff6400;
  font-size: 0.7rem;
  line-height: 1;
  border-radius: 15px;
}

#misomaga_sec2 .part2 .block .block_body p {
  font-size: 0.8rem;
  font-weight: normal;
  padding-bottom: 25px;
  padding-top: 10px;
}

#misomaga_sec2 .part2 .block .block_body .block_footer span {
  font-size: 0.6rem;
}

#words_sec_fv {
  height: 308px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-color: #cfcbc5;
}

#words_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#words_sec1 {
  background: #f5f3ee;
  padding-top: 75px;
  padding-bottom: 80px;
}

#words_sec1 .part1 {
  background: white;
  border-radius: 10px;
  padding: 35px 40px;
}

#words_sec1 .part1 h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#words_sec1 .part1 h3 span {
  margin-left: 12px;
  font-size: 0.9rem;
  font-weight: bold;
}

#words_sec1 .part1 .part_block {
  padding: 0 27px;
  padding-top: 36px;
}

#words_sec1 .part1 .part_block a {
  display: inline-flex;
  width: 45px;
  height: 45px;
  border-radius: 8px;
  border: 2px solid #ff6400;
  color: #ff6400;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  font-size: 1.2rem;
}

#words_sec1 .part2 {
  padding-top: 40px;
}

#words_sec1 .part2 h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff6400;
  padding-bottom: 7px;
}

#words_sec1 .part2 .block {
  margin-top: 40px;
  background: white;
  padding: 35px 40px;
  border-radius: 10px;
}

#words_sec1 .part2 .block_body {
  padding: 0 40px;
}

#words_sec1 .part2 .block_body dl {
  padding: 28px 0;
  display: flex;
  border-bottom: 1px solid black;
}

#words_sec1 .part2 .block_body dl:last-child {
  border-bottom: none;
}

#words_sec1 .part2 .block_body dl dt {
  width: 200px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-right: 27px;
}

#words_sec1 .part2 .block_body dl dd {
  flex: 1;
  font-size: 0.8rem;
}

#about_sec1 {
  background: #f5f3ee;
  padding-top: 100px;
  padding-bottom: 100px;
}

#about_sec1 h2 {
  padding-bottom: 75px;
}

#about_sec1 .part1 {
  background: white;
  border-radius: 10px;
  padding: 75px 85px;
}

#about_sec1 .part1 table {
  width: 100%;
}

#about_sec1 .part1 table tr th {
  font-weight: bold;
  font-size: 0.8rem;
  width: 180px;
  border-bottom: 1px solid #f2eee8;
  padding: 18px 0;
  text-align: left;
}

#about_sec1 .part1 table tr td {
  font-size: 0.8rem;
  border-bottom: 1px solid #f2eee8;
  padding: 18px 0;
}

#about_sec1 .part1 table tr td ul {
  padding: 10px 0;
}

#contact_sec1 {
  padding: 100px 0 200px;
  background: #f5f3ee;
}

#contact_sec1 p {
  font-size: 0.8rem;
  text-align: center;
}

#contact_sec2 .part1 {
  padding: 0 40px 0;
  border: 2px solid #f5f3ee;
  background: white;
  margin-top: -113px;
  border-radius: 10px;
}

#contact_sec2 .part1 .block {
  border-bottom: 1px solid #f2eee8;
  padding: 30px 0;
}

#contact_sec2 .part1 .block:last-child {
  border-bottom: none;
}

#contact_sec2 .part1 .block h3 {
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#contact_sec2 .part1 .block h3 span {
  font-size: 0.9rem;
  color: #ff6400;
  font-weight: bold;
}

#contact_sec2 .part1 .block h3 span:first-child {
  color: white;
  background: #ff6400;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

#contact_sec2 .part1 .block .block_body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#contact_sec2 .part1 .block .block_body p {
  padding-top: 30px;
  font-size: 0.6rem;
}

#contact_sec2 .part2 {
  padding-top: 100px;
}

#contact_sec3 {
  padding-top: 100px;
  background: #f5f3ee;
  padding-bottom: 100px;
}

#contact_sec3 h2 {
  padding-bottom: 70px;
}

#contact_sec3 .part1 {
  background: white;
  border-radius: 10px;
  padding: 40px;
}

#contact_sec3 .part1 h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 25px;
}

#contact_sec3 .part1 h3 span {
  font-size: 1.2rem;
  margin-left: 5px;
  font-weight: bold;
}

#contact_sec3 .part1 > p {
  font-size: 0.7rem;
  padding-bottom: 24px;
}

#contact_sec3 .part1 .block {
  display: flex;
  padding: 0 28px;
  padding-top: 34px;
  justify-content: space-between;
  border-top: 2px solid #f5f3ee;
}

#contact_sec3 .part1 .block .piece {
  padding: 10px 0;
}

#contact_sec3 .part1 .block .piece:first-child {
  flex: 1;
  border-right: 2px solid #f5f3ee;
}

#contact_sec3 .part1 .block .piece:last-child {
  padding-left: 60px;
}

#contact_sec3 .part1 .block .piece p {
  font-size: 0.7rem;
  padding-top: 8px;
}

#contact_sec_fv {
  height: 308px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-color: #cfcbc5;
}

#contact_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#faq_sec_fv {
  height: 308px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-color: #cfcbc5;
}

#faq_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#about_sec_fv {
  height: 308px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-color: #cfcbc5;
}

#about_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#faq_sec1 {
  background: #f5f3ee;
  padding-top: 100px;
  padding-bottom: 70px;
}

#faq_sec1 p {
  font-size: 0.8rem;
  text-align: center;
}

#faq_sec1 .part1 {
  margin-top: 80px;
}

#faq_sec1 .part1 #tabs {
  border: none;
  padding: 0;
  background: none;
}

#faq_sec1 .part1 #tabs ul {
  display: flex;
  padding: 0;
  border: none;
  margin: 0;
  margin-bottom: 50px;
  border-radius: 10px;
  overflow: hidden;
}

#faq_sec1 .part1 #tabs ul li {
  padding: 0;
  margin: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 65px;
  border: none;
}

#faq_sec1 .part1 #tabs ul li a {
  font-size: 0.9rem;
  font-weight: bold;
  color: #ff6400;
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#faq_sec1 .part1 #tabs ul li.ui-state-active {
  background: #ff6400;
}

#faq_sec1 .part1 #tabs ul li.ui-state-active a {
  color: white;
}

#faq_sec1 .part1 #tabs .content {
  padding: 0;
}

#faq_sec1 .part1 #tabs .content .block dl {
  margin-bottom: 30px;
}

#faq_sec1 .part1 #tabs .content .block dl dt {
  background: white;
  height: 72px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 0 40px;
  font-size: 0.9rem;
  font-weight: bold;
  position: relative;
  transition: all .4s;
  cursor: pointer;
}

#faq_sec1 .part1 #tabs .content .block dl dt .plus {
  position: absolute;
  right: 30px;
  width: 30px;
  height: 30px;
}

#faq_sec1 .part1 #tabs .content .block dl dt .plus::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background: black;
  top: 14px;
  right: 0;
  transition: all .4s;
}

#faq_sec1 .part1 #tabs .content .block dl dt .plus::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 30px;
  background: black;
  top: 0px;
  right: 14px;
  transform: rotate(0);
  transition: all .4s;
}

#faq_sec1 .part1 #tabs .content .block dl dt .red_mark {
  margin-right: 22px;
  background: #ff6400;
  display: inline-flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  font-size: 1.05rem;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  font-family: 'Jura', sans-serif;
}

#faq_sec1 .part1 #tabs .content .block dl dt.active {
  background: #ff6400;
  transition: all .4s;
  color: white;
}

#faq_sec1 .part1 #tabs .content .block dl dt.active .red_mark {
  background: white;
  color: #ff6400;
}

#faq_sec1 .part1 #tabs .content .block dl dt.active .plus::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background: white;
  top: 14px;
  right: 0;
  transition: all .4s;
}

#faq_sec1 .part1 #tabs .content .block dl dt.active .plus::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 30px;
  background: white;
  top: 0px;
  right: 14px;
  transform: rotate(90deg);
  transition: all .4s;
}

#faq_sec1 .part1 #tabs .content .block dl dd {
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  padding: 20px 40px;
  font-size: 0.9rem;
  display: none;
}

#faq_sec1 .part1 #tabs .content .block dl dd .blue_mark {
  margin-right: 22px;
  background: #ff6400;
  display: inline-flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  font-size: 1.05rem;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  font-family: 'Jura', sans-serif;
  vertical-align: top;
}

#faq_sec1 .part1 #tabs .content .block dl dd .detail {
  flex: 1;
  display: inline-block;
  width: 90%;
}

#members_sec_fv {
  height: 308px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  background-color: #cfcbc5;
}

#members_sec_fv h2 {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#members_sec1 {
  background: #f5f3ee;
  padding-top: 100px;
  padding-bottom: 80px;
}

#members_sec1 .part1 {
  background: white;
  border-radius: 10px;
  padding: 40px;
}

#members_sec1 .part1 h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#members_sec1 .part1 h3 span {
  font-size: 0.9rem;
  padding-left: 20px;
}

#members_sec1 .part1 .part_body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 30px;
}

#members_sec1 .part1 .part_body .block {
  color: #645845;
}

#members_sec1 .part1 .part_body .block p {
  text-align: center;
  font-size: 0.7rem;
  font-weight: bold;
  padding-top: 20px;
}

#members_sec1 .part1 .part_body .block p span {
  font-size: 0.8rem;
}

#members_sec2 {
  padding-top: 100px;
}

#members_sec2 .part1 .member_block {
  margin-top: 100px;
  padding-bottom: 50px;
  position: relative;
}

#members_sec2 .part1 .member_block::after {
  content: "";
  display: block;
  position: absolute;
  width: calc(50vw + 430px);
  height: calc(100% - 162px);
  background: #f5f3ee;
  bottom: 0;
  left: 85px;
  z-index: -1;
}

#members_sec2 .part1 .member_block .block1 {
  align-items: flex-start;
}

#members_sec2 .part1 .member_block .block1 .flex_body {
  padding-left: 44px;
}

#members_sec2 .part1 .member_block .block1 .flex_body h3 {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;
}

#members_sec2 .part1 .member_block .block1 .flex_body .profile1 {
  height: 136px;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  justify-content: flex-start;
  font-size: 0.7rem;
  position: relative;
}

#members_sec2 .part1 .member_block .block1 .flex_body .profile1::after {
  width: 150px;
  height: 2px;
  background: black;
  position: absolute;
  left: -100px;
  bottom: 0;
}

#members_sec2 .part1 .member_block .block1 .flex_body .profile2 {
  padding-top: 35px;
  font-size: 0.8rem;
  padding-right: 20px;
  line-height: 1.8;
}

#members_sec2 .part1 .member_block .block1.flex_img_right .flex_body {
  padding-left: 0;
  padding-right: 44px;
}

#members_sec2 .part1 .member_block .block1.flex_img_right .flex_body .profile1::after {
  width: 150px;
  height: 2px;
  background: black;
  position: absolute;
  left: auto;
  right: -100px;
  bottom: 0;
}

#members_sec2 .part1 .member_block > h3 {
  max-width: 950px;
  margin: auto;
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 56px;
  padding-bottom: 20px;
}

#members_sec2 .part1 .member_block .block2 {
  max-width: 950px;
  margin: auto;
  border: 2px solid #f5f3ee;
  border-radius: 10px;
  background: white;
  overflow-y: scroll;
  padding: 35px 40px;
  max-height: 400px;
}

#members_sec2 .part1 .member_block .block2::-webkit-scrollbar {
  width: 20px;
}

#members_sec2 .part1 .member_block .block2::-webkit-scrollbar-thumb {
  background: #ff6400;
  border-radius: 10px;
}

#members_sec2 .part1 .member_block .block2::-webkit-scrollbar-track {
  background: #f2eee8;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

#members_sec2 .part1 .member_block .block2 .content dl {
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
}

#members_sec2 .part1 .member_block .block2 .content dl dt {
  width: 75px;
  font-family: 'kiteOne', sans-serif;
  font-size: 0.7rem;
}

#members_sec2 .part1 .member_block .block2 .content dl dd {
  padding-left: 30px;
  border-left: 2px solid #f5f3ee;
}

#members_sec2 .part1 .member_block .block2 .content dl dd ul li {
  list-style: "・";
  font-size: 0.7rem;
}

#members_sec2 .part1 .member_block .block3 {
  max-width: 950px;
  margin: auto;
  border: 2px solid #f5f3ee;
  border-radius: 10px;
  background: white;
  padding: 35px 40px;
  overflow-y: scroll;
  max-height: 400px;
}

#members_sec2 .part1 .member_block .block3::-webkit-scrollbar {
  width: 20px;
}

#members_sec2 .part1 .member_block .block3::-webkit-scrollbar-thumb {
  background: #ff6400;
  border-radius: 10px;
}

#members_sec2 .part1 .member_block .block3::-webkit-scrollbar-track {
  background: #f2eee8;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

#members_sec2 .part1 .member_block .block3 .content ul li {
  padding-top: 20px;
  font-size: 0.7rem;
  margin-left: 20px;
  list-style: '・';
}

#members_sec2 .part1 .member_block .block3 .content ul li a {
  color: #ff6400;
}

#members_sec2 .part1 .member_block .absolute_body {
  position: absolute;
  top: 0;
  left: -85px;
}

#members_sec2 .part1 .member_block:nth-child(2n)::after {
  content: "";
  display: block;
  position: absolute;
  width: calc(50vw + 430px);
  height: calc(100% - 162px);
  background: #f5f3ee;
  bottom: 0;
  right: 85px;
  left: auto;
  z-index: -1;
}

#members_sec2 .part1 .member_block:nth-child(2n) .absolute_body {
  position: absolute;
  top: 0;
  right: -85px;
  left: auto;
}

a {
  cursor: pointer;
}

#privacy_sec1 {
  padding-bottom: 100px;
}

#privacy_sec1 h2 {
  padding-top: 80px;
  padding-bottom: 60px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

#privacy_sec1 h3 {
  font-size: 1rem;
  font-weight: bold;
  padding-top: 40px;
  padding-bottom: 10px;
}

#privacy_sec1 .part1 h3 {
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: left;
  font-weight: bold;
  font-size: 0.75rem;
}

#privacy_sec1 .part1 p {
  font-size: 0.75rem;
}

#privacy_sec1 .part1 a {
  color: #3984c8;
}

#privacy_sec1 .part1 .hidden {
  display: none;
}

.breadcrumb {
  padding-top: 30px;
}

.breadcrumb p {
  font-size: 10px;
}

.breadcrumb p a {
  font-size: 10px;
}

.breadcrumb p .current {
  color: #ff6400;
  font-size: 10px;
}

#article_sec1 .wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 85px;
}

#article_sec1 .wrapper .content_left {
  width: 710px;
}

#article_sec1 .wrapper .content_left h2 {
  font-size: 1.4rem;
  text-align: left;
}

#article_sec1 .wrapper .content_left .column_detail_date {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  width: 100%;
}

#article_sec1 .wrapper .content_left .column_detail_date .keywords {
  display: flex;
}

#article_sec1 .wrapper .content_left .column_detail_date .keywords .term {
  display: inline-block;
  border: 1px solid #ff6400;
  padding: 3px 10px;
  border-radius: 13px;
  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1;
  color: #ff6400;
  margin-right: 10px;
}

#article_sec1 .wrapper .content_left .column_detail_date .date {
  font-size: 0.7rem;
  font-family: 'kiteOne', sans-serif;
}

#article_sec1 .wrapper .content_left .sns_btns {
  display: flex;
}

#article_sec1 .wrapper .content_left .sns_btns a {
  display: flex;
  flex: 1;
  height: 50px;
  justify-content: center;
  align-items: center;
}

#article_sec1 .wrapper .content_left .sns_btns a.fb_btn {
  background: #27438a;
}

#article_sec1 .wrapper .content_left .sns_btns a.twitter_btn {
  background: #279bf1;
}

#article_sec1 .wrapper .content_left .sns_btns a.line_btn {
  background: #18b705;
}

#article_sec1 .wrapper .content_left .content_detail {
  padding: 70px 0;
}

#article_sec1 .wrapper .content_left .sub_ttl {
  font-size: 0.8rem;
  font-weight: bold;
  padding-bottom: 22px;
}

#article_sec1 .wrapper .content_left .page_btns {
  display: flex;
  padding: 32px 0;
  border-top: 2px solid #a29888;
  border-bottom: 2px solid #a29888;
  margin-top: 50px;
  margin-bottom: 100px;
}

#article_sec1 .wrapper .content_left .page_btns .prev_page {
  flex: 1;
  font-size: 0.7rem;
  color: #a29888;
  text-align: left;
  font-family: 'Jura', sans-serif;
  display: block;
  font-weight: bold;
}

#article_sec1 .wrapper .content_left .page_btns .list_top {
  flex: 1;
  font-size: 0.7rem;
  color: #a29888;
  text-align: center;
  display: block;
  font-weight: bold;
}

#article_sec1 .wrapper .content_left .page_btns .next_page {
  flex: 1;
  font-family: 'Jura', sans-serif;
  font-size: 0.7rem;
  font-weight: bold;
  color: #a29888;
  text-align: right;
  display: block;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper {
  padding-bottom: 16px;
  border-bottom: 2px solid #a29888;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper h2 {
  font-size: 0.8rem;
  font-weight: bold;
  border-left: 5px solid #ff6400;
  padding-left: 15px;
  margin-bottom: 30px;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block {
  width: 222px;
  margin-right: 22px;
  margin-bottom: 32px;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block:nth-child(3n) {
  margin-right: 0;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a {
  display: block;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .content_pickup_img {
  width: 100%;
  height: 148px;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .content_pickup_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .date {
  font-size: 0.5rem;
  font-family: "KiteOne", sans-serif;
  padding: 15px 0;
  line-height: 1;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .content_pickup_title {
  font-size: 0.65rem;
  font-weight: bold;
}

#article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .content_terms_part {
  font-size: 0.5rem;
  text-align: right;
  padding-top: 10px;
}

#article_sec1 .wrapper .content_left .btn_group {
  display: flex;
  padding-top: 90px;
  padding-bottom: 150px;
  justify-content: space-between;
}

#article_sec1 .wrapper .content_left .btn_group a {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

#article_sec1 .wrapper .content_left .btn_group a.btn1 {
  background: #645845;
  border-radius: 10px;
  height: 85px;
}

#article_sec1 .wrapper .content_left .btn_group a.btn2 {
  border-radius: 10px;
  height: 85px;
  background: #ff6400;
  text-align: center;
}

#article_sec1 .wrapper .content_right {
  width: 280px;
}

#article_sec1 .wrapper .content_right .content_pickup h3 {
  font-weight: bold;
  font-size: 0.8rem;
  padding: 10px 12px;
  line-height: 1;
  border-left: 5px solid #ff6400;
  margin-bottom: 20px;
}

#article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block {
  padding: 12px 0;
  border-bottom: 1px solid #a29888;
}

#article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a {
  display: flex;
  width: 100%;
}

#article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a .piece {
  width: 120px;
}

#article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a .piece img {
  width: 100%;
  height: 80px;
  object-fit: cover;
}

#article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a .piece .recomment_column_date {
  font-size: 10px;
  font-family: "KiteOne", sans-serif;
  padding-top: 8px;
}

#article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a .recomment_column_ttl {
  font-size: 0.65rem;
  padding-left: 10px;
}

#article_sec1 .wrapper .content_right .content_category {
  padding-top: 50px;
}

#article_sec1 .wrapper .content_right .content_category h3 {
  font-weight: bold;
  font-size: 0.8rem;
  padding: 10px 12px;
  line-height: 1;
  border-left: 5px solid #ff6400;
  margin-bottom: 20px;
}

#article_sec1 .wrapper .content_right .content_category .content_category_list {
  display: flex;
  flex-wrap: wrap;
}

#article_sec1 .wrapper .content_right .content_category .content_category_list a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 0.7rem;
  font-weight: bold;
  color: #ff6400;
  padding: 5px 12px;
  border: 1px solid #ff6400;
  border-radius: 14px;
  margin-right: 13px;
  margin-bottom: 15px;
}

#contact_sec_part {
  padding-bottom: 100px;
}

#contact_sec_part h2 {
  text-align: center;
  padding: 75px 0 70px;
}

#contact_sec_part .content {
  width: 840px;
  margin: auto;
}

#contact_sec_part .content .form-group {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f5f3ee;
}

#contact_sec_part .content .form-group label {
  font-size: 14px;
}

#contact_sec_part .content .form-group .wpcf7-list-item {
  margin-left: 0;
}

#contact_sec_part .content .form-group > label {
  display: flex;
  width: 288px;
  font-size: 14px;
  /* margin-bottom: 20px; */
  position: relative;
  align-items: flex-start;
  padding-top: 15px;
}

#contact_sec_part .content .form-group > label .essential {
  position: absolute;
  font-size: 10px;
  right: 20px;
  top: 15px;
  background: #ff6400;
  padding: 3px 10px;
  color: white;
}

#contact_sec_part .content .form-group > label .unessential {
  position: absolute;
  font-size: 10px;
  right: 20px;
  top: 15px;
  background: #f2eee8;
  padding: 3px 10px;
  color: white;
  color: #645845;
}

#contact_sec_part .content .form-group .wpcf7-list-item.last {
  font-size: 13px;
  align-self: center;
}

#contact_sec_part .content .form-group .wpcf7-list-item .wpcf7-list-item-label {
  padding-right: 20px;
}

#contact_sec_part .content .form-group .wpcf7-list-item.first {
  padding-top: 7px;
}

#contact_sec_part .content .form-group .wpcf7-list-item.first label input {
  display: none;
}

#contact_sec_part .content .form-group .wpcf7-list-item.first label .wpcf7-list-item-label {
  color: #ff6400;
  font-size: 0.6rem;
  font-weight: bold;
}

#contact_sec_part .content .form-group .wpcf7-form-control-wrap {
  flex: 1;
}

#contact_sec_part .content .form-group input[type=text], #contact_sec_part .content .form-group input[type=email], #contact_sec_part .content .form-group input[type=tel], #contact_sec_part .content .form-group select, #contact_sec_part .content .form-group textarea {
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  background-color: #edeeee;
  border: none;
  padding-left: 20px;
  color: #363636;
  font-size: 15px;
}

#contact_sec_part .content .form-group input[type=checkbox] {
  background-color: #f5f3ee;
  border: none;
  outline: none;
}

#contact_sec_part .content .form-group textarea {
  height: 120px;
  resize: vertical;
}

#contact_sec_part .content .wpcf7-list-item.first.last {
  font-size: 13px;
  align-self: center;
  width: 100%;
  text-align: center;
  margin-left: 0;
}

#contact_sec_part .content .wpcf7-not-valid-tip {
  font-size: 0.7rem;
}

#contact_sec_part .content .submit_btn {
  text-align: center;
  margin-top: 28px;
}

#contact_sec_part .content .submit_btn .ajax-loader {
  display: none;
}

#contact_sec_part .content .submit_btn input {
  background: #221815;
  width: 300px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  background: url(./../images/contact.png);
  background-size: cover;
}

#contact_sec_part .content .submit_btn input:hover {
  opacity: 0.8;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
}

.pagination a, .pagination span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.pagination span.current {
  border: none;
  background: #ff6400;
  color: white;
}

.pagination span.current.current {
  background: #ff6400;
  color: white;
}

.pagination a {
  border: 1px solid #ff6400;
}

.pagination a:hover {
  color: white;
  background: #ff6400;
}

.easy-wp-page-navigation {
  text-align: center;
}

.easy-wp-page-navigation ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.easy-wp-page-navigation ul li {
  width: 45px;
  height: 45px;
  margin-right: 20px;
}

.easy-wp-page-navigation ul li.last-page {
  width: auto;
  height: auto;
}

.easy-wp-page-navigation ul li.last-page a, .easy-wp-page-navigation ul li.last-page span {
  border: none;
  background: none;
}

.easy-wp-page-navigation ul li.last-page a:hover, .easy-wp-page-navigation ul li.last-page span:hover {
  border: none;
  background: none;
}

.easy-wp-page-navigation ul li a, .easy-wp-page-navigation ul li span {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
}

.easy-wp-page-navigation ul li span {
  border: none;
  background: #ff6400;
}

.easy-wp-page-navigation ul li span.current {
  background: #ff6400;
}

.easy-wp-page-navigation ul li a {
  border: 1px solid #ff6400;
}

.easy-wp-page-navigation ul li a:hover {
  background: #ff6400;
}

#wrap_contact {
  width: 100%;
}

#wrap_contact table {
  width: 100%;
}

#wrap_contact table tr th,
#wrap_contact table tr td {
  padding: 0.5em;
  text-align: left;
  vertical-align: top;
  position: relative;
}

#wrap_contact table tr th {
  width: 288px;
  font-size: 14px;
}

.essential {
  position: absolute;
  font-size: 10px;
  right: 20px;
  top: 15px;
  background: #ff6400;
  padding: 3px 10px;
  color: white;
}

.unessential {
  position: absolute;
  font-size: 10px;
  right: 20px;
  top: 15px;
  background: #f2eee8;
  padding: 3px 10px;
  color: white;
  color: #645845;
}

#wrap_contact table tr td {
  font-size: 14px;
}

#wrap_contact .wpcf7-list-item {
  padding-top: 7px;
}

#wrap_contact .wpcf7-list-item.first {
  color: #ff6400;
  font-size: 0.6rem;
  font-weight: bold;
}

#wrap_contact .wpcf7-list-item.first label input {
  display: none;
}

#wrap_contact input[type="text"],
#wrap_contact input[type="email"],
#wrap_contact input[type="tel"],
#wrap_contact select,
#wrap_contact textarea {
  padding: 0.7em;
  width: 100%;
  font-size: 15px;
  height: 50px;
  background-color: #edeeee;
  outline: none;
  border: none;
}

#wrap_contact textarea {
  height: 120px;
}

.wpcf7-form-mode-edit #wrap_contact input[type="button"].button-confirm,
.wpcf7-form-mode-confirm #wrap_contact input[type="submit"], .wpcf7-form-mode-confirm #wrap_contact input[type="button"] {
  width: 300px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.wpcf7-form-mode-edit #wrap_contact input[type="button"] {
  background: url(./../images/contact.png);
  background-size: cover;
}

.wpcf7-form-mode-confirm #wrap_contact input[type="submit"] {
  background: url(./../images/confirm.png);
  background-size: cover;
}

.wpcf7-form-mode-confirm #wrap_contact input[type="button"].button-rewrite {
  background: url(./../images/back.png);
  background-size: cover;
}

.wpcf7-form-mode-confirm #wrap_contact table th span,
.wpcf7-form-mode-confirm #wrap_contact .txt_edit,
.wpcf7-form-mode-confirm #wrap_contact .txt_sent {
  display: none;
}

.wpcf7-form-mode-edit #wrap_contact .txt_confirm,
.wpcf7-form-mode-edit #wrap_contact .txt_sent {
  display: none;
}

.wrap_error .error-messages {
  font-size: 14px;
  color: #ff6400;
}

.sent #wrap_contact .txt_confirm,
.sent #wrap_contact .txt_edit,
.sent #wrap_contact table,
.sent #wrap_contact h2 {
  display: none;
}

.sent #wrap_contact .txt_sent {
  display: block;
}

div.wpcf7-mail-sent-ok {
  border: none;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  #wrap_contact {
    width: 100%;
  }
  #wrap_contact table,
  #wrap_contact table tbody,
  #wrap_contact table tr,
  #wrap_contact table tr th,
  #wrap_contact table tr td {
    display: block;
  }
  #wrap_contact table {
    width: 100%;
    border-width: 0 0 1px 0;
  }
  #wrap_contact table tr th,
  #wrap_contact table tr td {
    width: 100%;
    padding: 0;
  }
  #wrap_contact table tr td {
    border-width: 0px 1px 0px 1px;
  }
  #wrap_contact table tr th {
    height: 13vw;
    padding-top: 4vw;
  }
  form.wpcf7-form p.buttons-area .button-rewrite {
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
  form.wpcf7-form p.buttons-area .wpcf7-submit {
    margin-left: 0 !important;
  }
}

.arrow_box {
  position: relative;
  width: 100%;
  height: 48px;
  display: inline-block;
  background: #FFFFFF;
  padding: 10px;
  text-align: center;
  border: 1px solid #000000;
  color: #000000;
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.arrow_box:after, .arrow_box:before {
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 12%;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-width: 11px;
  border-bottom-width: 11px;
  border-left-width: 9px;
  border-right-width: 9px;
  margin-left: -9px;
  border-top-color: #FFFFFF;
}

.arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-top-width: 12px;
  border-bottom-width: 12px;
  border-left-width: 10px;
  border-right-width: 10px;
  margin-left: -10px;
  margin-top: 1px;
  border-top-color: #000000;
}

/* CSS Document */
@media screen and (min-width: 767px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 3.8vw;
  }
  .pc {
    display: none !important;
  }
  .fixedcontainer {
    width: 100%;
  }
  body {
    font-size: 3.8vw;
    padding-top: 20vw;
  }
  .header_top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 20vw;
    z-index: 1002;
    background: white;
  }
  .header_top .header1 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20vw;
    position: relative;
  }
  .header_top .header1 h1 {
    position: absolute;
    width: 100%;
    left: 0;
    line-height: 1;
    display: flex;
  }
  .header_top .header1 h1 a {
    flex: 1;
    text-align: center;
  }
  .header_top .header1 h1 img {
    width: 60%;
  }
  .header_top .header1 .sns_btns {
    display: inline-flex;
    height: 100%;
    align-items: center;
  }
  .header_top .header1 .sns_btns a {
    margin-right: 26px;
    margin-left: 0;
  }
  .header_top .header2 {
    background: #f5f3ee;
    display: none;
  }
  .header_top .header2 .btn_group {
    height: 8vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .header_top .header2 .btn_group a {
    font-size: 0.7rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #645845;
  }
  .header_top .header2 .btn_group a.active {
    border-bottom: 4px solid #ff6400;
  }
  .header_top p {
    padding-right: 40px;
  }
  .header_top a {
    color: white;
    font-size: 0.8rem;
    display: inline-block;
    margin-left: 0;
  }
  .flex_part .flex_img_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .flex_part .flex_img_right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .flex_part .flex_multi {
    flex-direction: column;
  }
  .g_nav {
    position: fixed;
    width: 100%;
    top: 60px;
    z-index: 100;
    display: none;
    background: rgba(255, 255, 255, 0.85);
    height: 100%;
  }
  .menu-trigger {
    transition: all .4s;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    margin-top: 3px;
    width: 60px;
    height: 60px;
    z-index: 2000;
  }
  .menu-trigger:hover {
    opacity: 1;
  }
  .menu-trigger span {
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    cursor: pointer;
    float: right;
    position: absolute;
    width: 24px;
    height: 3px;
    left: 0;
    background: #A77E03;
    right: 0;
    margin: auto;
  }
  .menu-trigger span:nth-of-type(1) {
    top: 20px;
  }
  .menu-trigger span:nth-of-type(2) {
    top: 0;
    bottom: 0;
  }
  .menu-trigger span:nth-of-type(3) {
    bottom: 20px;
  }
  .menu-trigger.active span:nth-of-type(1) {
    transform: translateY(8.5px) rotate(-45deg);
  }
  .menu-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }
  .menu-trigger.active span:nth-of-type(3) {
    transform: translateY(-8.5px) rotate(45deg);
  }
  .g_nav ul {
    width: 90vw;
    display: flex;
    height: unset;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    letter-spacing: 0.1em;
    padding-bottom: 5vw;
    margin-top: 5vw;
  }
  .g_nav ul li {
    float: none;
    width: 100%;
    font-size: 1rem;
    margin: 1.5vw 0;
    text-align: center;
  }
  .g_nav ul li a {
    display: block;
    padding: 2vw 0;
    color: black;
    font-weight: bold;
    text-decoration: none;
  }
  .g_nav ul li a:hover {
    opacity: 0.9;
    color: black;
    background: #fff;
  }
  .sp_drag {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
  }
  .sp_drag_part {
    text-align: center;
    padding: 2vw 5px 1.5vw;
  }
  ul.sp_drag_btns {
    list-style: none;
    width: 100%;
  }
  ul.sp_drag_btns li {
    float: left;
    width: 50%;
  }
  ul.sp_drag_btns li a {
    display: block;
    width: 96%;
    display: block;
    margin: 0 auto;
  }
  ul.sp_drag_btns li a img {
    width: 100%;
    vertical-align: middle;
  }
  .top {
    margin-top: 66px;
    height: 133vw;
  }
  .top .container {
    width: 100%;
  }
  .loopSliderWrap1 {
    width: 100%;
    height: 133vw;
  }
  .loopSlider1 {
    width: 100%;
    height: 133vw;
  }
  .loopSlider1 ul {
    width: 100%;
    height: 133vw;
  }
  .loopSlider1 ul li {
    width: 100vw;
    height: 133vw;
  }
  .loopSlider1 ul li div {
    width: 100vw;
    height: 133vw;
  }
  #pc_menu2 {
    width: 100%;
    min-width: 1200px;
    margin: 0 auto;
    padding: 35px 0;
    background: #f6f6f6;
    text-align: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
  }
  #pc_menu2 .top_menu_link {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 1.4;
    font-size: 16px;
    color: #814900;
    padding: 0 30px;
    border-right: 1px solid #ccc;
  }
  #pc_menu2 .top_menu_link span {
    font-size: 22px;
    font-weight: bold;
    color: #444;
  }
  #pc_menu2 .top_menu_link:hover {
    opacity: 0.3;
  }
  #pc_menu2 .top_menu_link:last-child {
    border-right: 0px solid #ccc;
  }
  #drag_side {
    display: block;
    position: fixed;
    z-index: 101;
    right: -200px;
    top: auto;
    bottom: 15vw;
    width: 13vw;
    max-width: 140px;
  }
  h2 {
    text-align: center;
    max-width: 90vw;
    margin: auto;
  }
  h2 .nihongo {
    font-size: 1.8rem;
    font-weight: bold;
    display: block;
    letter-spacing: 0.5px;
  }
  h2 .eigo {
    font-size: 0.9rem;
    display: block;
    letter-spacing: 1px;
  }
  #sec_fv {
    height: 80vw;
    background: url(./../images/fv_sp.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  #sec1 > p {
    padding-top: 10vw;
    font-size: 1rem;
    line-height: 1.7;
    text-align: left;
    width: 90vw;
    margin: auto;
  }
  #sec1 h2 {
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  #sec1 .part1 {
    width: 90vw;
    margin: auto;
    padding-bottom: 10vw;
  }
  #sec1 .part1 .block1 .flex_part {
    padding: 0 2.5vw;
  }
  #sec1 .part1 .block1 .flex_part > img {
    width: 100%;
    height: 58.4vw;
  }
  #sec1 .part1 .block1 .flex_part h3 {
    font-size: 1rem;
    font-weight: bold;
    height: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #sec1 .part1 .block1 .flex_part p {
    font-size: 0.9rem;
  }
  #sec1 .part1 .block1 .flex_part .readmore_btn {
    text-align: right;
    padding-top: 25px;
    font-family: 'Jura', sans-serif;
    font-size: 0.8rem;
  }
  #sec1 .part1 .block1 .slick-prev, #sec1 .part1 .block1 .slick-next {
    top: 80px;
    transform: none;
  }
  #sec1 .part1 .block1 .slick-prev {
    left: 10vw;
    width: 8vw;
    height: 8vw;
    background: url(./../images/arrow_l.png);
    background-size: contain;
  }
  #sec1 .part1 .block1 .slick-prev::after {
    content: none;
  }
  #sec1 .part1 .block1 .slick-prev::before {
    content: none;
  }
  #sec1 .part1 .block1 .slick-next {
    right: 10vw;
    width: 8vw;
    height: 8vw;
    background: url(./../images/arrow_r.png);
    background-size: contain;
  }
  #sec1 .part1 .block1 .slick-next::after {
    content: none;
  }
  #sec1 .part1 .block1 .slick-next::before {
    content: none;
  }
  #sec1 .part1 .block2 {
    padding-top: 7vw;
    text-align: center;
  }
  #sec1 .part1 .block2 a {
    display: inline-block;
  }
  #sec2 {
    background: #f5f3ee;
  }
  #sec2 h2 {
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  #sec2 .part1 {
    padding-bottom: 10vw;
    width: 90vw;
    margin: auto;
  }
  #sec2 .part1 #tabs {
    border: none;
    background: none;
    font-family: YuGothic,"游ゴシック","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic Pro", "メイリオ","Meiryo",Osaka,Meiryo,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
  }
  #sec2 .part1 #tabs ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    background: none;
    border: none;
    border-bottom: 2px solid #ff6400;
  }
  #sec2 .part1 #tabs ul li {
    flex: 1;
    height: 14vw;
    margin-right: 0;
    background: white;
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #f5f3ee;
  }
  #sec2 .part1 #tabs ul li:last-child {
    margin-right: 0;
  }
  #sec2 .part1 #tabs ul li.ui-state-active {
    background: #ff6400;
    border: 1px solid #f5f3ee;
  }
  #sec2 .part1 #tabs ul li.ui-state-active a {
    color: white;
  }
  #sec2 .part1 #tabs ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    font-size: 0.85rem;
    color: #ff6400;
    width: 100%;
    height: 100%;
  }
  #sec2 .part1 #tabs .content {
    padding-bottom: 8vw;
    margin-top: -48vw;
    padding: 0;
    padding-top: 48vw;
  }
  #sec2 .part1 #tabs .content > div {
    padding: 4vw 4vw 6vw;
    background: white;
  }
  #sec2 .part1 #tabs .content p {
    font-size: 1rem;
    color: #645845;
  }
  #sec2 .part1 #tabs .content .img_group {
    display: flex;
    justify-content: space-evenly;
    padding-top: 6vw;
    padding-bottom: 6vw;
  }
  #sec2 .part1 #tabs .content .img_group img {
    width: 50%;
    height: 30vw;
    object-fit: cover;
  }
  #sec2 .part1 #tabs .content .img_group img:first-child {
    margin-right: 1vw;
  }
  #sec2 .part1 #tabs .content .img_group img:last-child {
    margin-left: 1vw;
  }
  #sec2 .part1 #tabs .content .img_group img.contain_img {
    object-fit: contain;
  }
  #sec2 .part1 #tabs .content .btn_block {
    text-align: center;
  }
  #sec2 .part1 #tabs .content .btn_block a {
    display: inline-block;
  }
  #sec3 h2 {
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  #sec3 .part1 {
    width: 90vw;
    margin: auto;
  }
  #sec3 .part1 .block .flex_body {
    padding-right: 0;
    padding-top: 6vw;
  }
  #sec3 .part1 .block .flex_body h3 {
    font-size: 1.2rem;
    font-weight: bold;
    padding-bottom: 4vw;
  }
  #sec3 .part1 .block .flex_body p {
    font-size: 1rem;
  }
  #sec3 .part2 {
    width: 90vw;
    margin: auto;
    padding-bottom: 10vw;
  }
  #sec3 .part2 h3 {
    line-height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 8vw;
  }
  #sec3 .part2 h3::before {
    content: "";
    border-radius: 50%;
    width: 4vw;
    height: 4vw;
    border: 1.5vw solid #ff6400;
    display: inline-block;
  }
  #sec3 .part2 h3 .nihongo {
    padding-left: 2.5vw;
    font-size: 1.2rem;
    font-weight: bold;
  }
  #sec3 .part2 h3 .eigo {
    font-size: 0.9rem;
    font-weight: bold;
  }
  #sec3 .part2 h3 .slash {
    margin: 0 30px;
  }
  #sec3 .part2 .part_body {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #sec3 .part2 .block {
    flex: none;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10vw;
    align-items: center;
    justify-content: flex-start;
  }
  #sec3 .part2 .block h4 {
    height: 20vw;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 4vw;
  }
  #sec3 .part2 .block h4 .name {
    font-size: 0.9rem;
  }
  #sec3 .part2 .block p {
    text-align: left;
    font-size: 0.9rem;
  }
  #sec3 .part2 .btn_part {
    text-align: center;
    margin-top: 6vw;
  }
  #sec3 .part2 .btn_part a {
    display: inline-block;
  }
  #sec4 {
    background: #f5f3ee;
    padding-bottom: 15vw;
  }
  #sec4 h2 {
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  #sec4 .part {
    width: 90vw;
    margin: auto;
    background: white;
    padding-top: 6vw;
    padding-bottom: 0;
    margin-bottom: 5vw;
  }
  #sec4 .part h3 {
    text-align: center;
  }
  #sec4 .part h3 span {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0 5px;
    border-bottom: 2px solid #ff6400;
  }
  #sec4 .part .block {
    padding-top: 8vw;
  }
  #sec4 .part .block .slick-next, #sec4 .part .block .slick-prev {
    width: 8vw;
  }
  #sec4 .part .block .slick-next::after, #sec4 .part .block .slick-next::before, #sec4 .part .block .slick-prev::after, #sec4 .part .block .slick-prev::before {
    content: none;
  }
  #sec4 .part .block .slick-next {
    background: url(./../images/arrow_r.png) no-repeat;
    background-size: contain;
    right: 8vw;
  }
  #sec4 .part .block .slick-prev {
    background: url(./../images/arrow_l.png) no-repeat;
    background-size: contain;
    left: 8vw;
  }
  #sec4 .part .block .slicker_part {
    padding: 0 20px;
  }
  #sec4 .part .block .slicker_part h4 {
    text-align: center;
    padding-top: 0;
    padding-bottom: 15px;
  }
  #sec4 .part .block .slicker_part img {
    width: 304px;
    height: 205px;
    object-fit: cover;
  }
  #sec4 .part .block .slicker_part > h5 {
    height: auto;
    text-align: left;
  }
  #sec4 .part .block .slicker_part > h5 img {
    height: auto;
    width: 300px;
    object-fit: contain;
  }
  #sec4 .part .block .slicker_part > h6 {
    font-size: 1rem;
    font-weight: bold;
    padding: 6vw 0 2vw;
    text-align: center;
    height: auto;
  }
  #sec4 .part .block .slicker_part > p {
    font-size: 0.9rem;
    padding: 10px 0;
    height: 25vw;
  }
  #sec4 .part .block .slicker_part .content {
    padding-top: 30px;
    display: none;
  }
  #sec4 .part .block .slicker_part .content h5 {
    height: auto;
    text-align: left;
  }
  #sec4 .part .block .slicker_part .content h5 img {
    height: auto;
    width: 300px;
    object-fit: contain;
  }
  #sec4 .part .block .slicker_part .content h6 {
    font-size: 1rem;
    font-weight: bold;
    padding: 6vw 0 2vw;
    text-align: center;
  }
  #sec4 .part .block .slicker_part .content p {
    font-size: 0.9rem;
    padding: 10px 0;
  }
  #sec4 .part .block .slicker_part a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff6400;
    text-align: center;
  }
  #sec4 .part .block .slicker_part a.hidden {
    display: none;
  }
  #sec4 .part .block .slicker_part a span {
    color: white;
    font-size: 0.9rem;
    line-height: 1;
    padding: 2vw 0;
    display: inline-block;
  }
  #sec4 .part .block .slicker_part a span::before {
    color: #ff6400;
    font-weight: bold;
    background: white;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4vw;
    height: 4vw;
    margin-right: 20px;
  }
  #sec4 .part .block .slicker_part a.voice_readmore span::before {
    content: "↓";
  }
  #sec4 .part .block .slicker_part a.voice_readless span::before {
    content: "↑";
  }
  #sec4 .part .block .slick-dots {
    bottom: -6vw;
  }
  #sec4 .part .block .slick-dots li {
    height: 6px;
  }
  #sec4 .part .block .slick-dots li button {
    width: 40px;
    height: 6px;
    border-radius: 6px;
    background: #a29888;
  }
  #sec4 .part .block .slick-dots li button::after, #sec4 .part .block .slick-dots li button::before {
    content: none;
  }
  #sec4 .part .block .slick-dots li.slick-active button {
    background: #ff6400;
  }
  #sec5 h2 {
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  #sec5 > p {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 8vw;
  }
  #sec5 .part1 {
    width: 90vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #sec5 .part1 .block {
    margin: 3vw 0;
    width: 48%;
  }
  #sec5 .part1 .block > img {
    width: 100%;
    height: 25vw;
  }
  #sec5 .part1 .block h3 {
    font-size: 0.9rem;
    font-weight: bold;
    height: 10vw;
    margin-top: 3vw;
    margin-bottom: 2vw;
  }
  #sec5 .part1 .block p {
    font-size: 0.8rem;
    line-height: 1.7;
  }
  #sec5 .part1 .block:nth-child(odd) {
    margin-right: 2%;
  }
  #sec5 .part1 .block:nth-child(even) {
    margin-left: 2%;
  }
  #sec5 .part2 {
    width: 90vw;
    margin: auto;
    margin-top: 8vw;
    padding-bottom: 10vw;
    text-align: center;
  }
  #sec5 .part2 a {
    display: inline-block;
  }
  #sec6 {
    background: #f5f3ee;
  }
  #sec6 h2 {
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  #sec6 .part1 {
    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }
  #sec6 .part1 .block {
    background: white;
    padding: 4vw 4vw;
    width: 100%;
    margin-bottom: 4vw;
    border-radius: 1.5vw;
    margin-right: 0;
  }
  #sec6 .part1 .block .block_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #sec6 .part1 .block .block_header span {
    display: inline-block;
  }
  #sec6 .part1 .block .block_header span:first-child {
    font-size: 0.95rem;
    padding: 0 4px;
    color: white;
  }
  #sec6 .part1 .block .block_header span:first-child.news_category {
    background: #ff6400;
  }
  #sec6 .part1 .block .block_header span:first-child.events_category {
    background: #21dc21;
  }
  #sec6 .part1 .block .block_header span:first-child.media_category {
    background: #3866aa;
  }
  #sec6 .part1 .block .block_header span:last-child {
    font-size: 0.8rem;
  }
  #sec6 .part1 .block .block_header .news_category {
    font-size: 0.95rem;
  }
  #sec6 .part1 .block h3 {
    font-size: 1rem;
    padding: 25px 0;
  }
  #sec6 .part1 .block p {
    font-size: 0.8rem;
  }
  #sec6 .part1 .block .block_footer {
    padding-top: 35px;
  }
  #sec6 .part1 .block .block_footer span {
    font-size: 0.8rem;
  }
  #sec6 .part1 .block .readmore_btn {
    text-align: left;
    padding-top: 25px;
    font-family: 'Jura', sans-serif;
    font-size: 0.8rem;
  }
  #sec6 .part2 {
    width: 90vw;
    margin: auto;
    padding-bottom: 8vw;
    padding-top: 6vw;
    text-align: center;
  }
  #sec6 .part2 a {
    display: inline-block;
  }
  #sec6 .part3 {
    width: 90vw;
    max-width: 1200px;
    margin: auto;
    border-top: 2px solid white;
    padding: 8vw 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  #sec6 .part3 a {
    flex: none;
    width: 45%;
    margin-right: 0;
    margin-bottom: 2vw;
    display: inline-block;
  }
  #product_sec_fv {
    height: 35.4vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  #brand_sec_fv {
    height: 35.4vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  #product_sec_fv {
    height: 35.4vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  #ec_sec_fv {
    height: 35.4vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  #school_sec_fv {
    height: 35.4vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  #local_sec_fv {
    height: 35.4vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
  }
  #services_sec1 {
    background: #f5f3ee;
    padding-top: 6vw;
    padding-bottom: 8vw;
  }
  #services_sec1 .part1 {
    width: 90vw;
    margin: auto;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    background: white;
    padding: 8vw 4vw;
    flex-wrap: wrap;
  }
  #services_sec1 .part1 h2 {
    width: 100%;
    padding-bottom: 3vw;
    display: flex;
    justify-content: flex-start;
    font-size: 0.9rem;
    align-items: center;
  }
  #services_sec1 .part1 h2 span {
    margin-left: 10px;
  }
  #services_sec1 .part1 .block {
    width: 50%;
    height: 16vw;
    background: #f5f3ee;
    border-radius: 10px;
    border: 2px solid white;
  }
  #services_sec1 .part1 .block.active {
    background: #ff6400;
  }
  #services_sec1 .part1 .block.active a {
    color: white;
  }
  #services_sec1 .part1 .block a {
    font-weight: bold;
    font-size: 1rem;
    display: inline-block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.7;
    color: #645845;
  }
  #services_sec2 {
    padding-bottom: 10vw;
  }
  #services_sec2 h2 {
    padding-top: 10vw;
    padding-bottom: 6vw;
  }
  #services_sec2 .circle_mark {
    display: inline-block;
    width: 8vw;
    height: 8vw;
    margin-right: 10px;
    border: 6px solid #ff6400;
    border-radius: 50%;
  }
  #services_sec2 > p {
    width: 90vw;
    margin: auto;
    font-size: 0.95rem;
    line-height: 1.7;
    text-align: left;
  }
  #services_sec2 .part1 {
    background: #f5f3ee;
    padding-bottom: 16vw;
    margin-top: 10vw;
  }
  #services_sec2 .part1 h3 {
    font-size: 1.4rem;
    color: #ff6400;
    text-align: center;
    padding-top: 8vw;
    padding-bottom: 6vw;
  }
  #services_sec2 .part1 .block1 {
    width: 90vw;
    margin: auto;
    background: white;
    border-radius: 10px;
    padding: 4vw;
    margin-bottom: 6vw;
  }
  #services_sec2 .part1 .block1 h4 {
    text-align: center;
    margin-bottom: 5vw;
  }
  #services_sec2 .part1 .block1 ul li {
    font-size: 1.05rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2vw;
  }
  #services_sec2 .part1 .block1 ul li span {
    margin-left: 2vw;
  }
  #services_sec2 .part1 .block2 {
    width: 90vw;
    margin: auto;
    padding-top: 10vw;
  }
  #services_sec2 .part1 .block2 h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-bottom: 4vw;
  }
  #services_sec2 .part1 .block2 .block_body {
    background: white;
    padding: 6vw 5vw;
    border-radius: 10px;
  }
  #services_sec2 .part1 .block2 .block_body .slick-next, #services_sec2 .part1 .block2 .block_body .slick-prev {
    width: 8vw;
    height: 8vw;
  }
  #services_sec2 .part1 .block2 .block_body .slick-next::after, #services_sec2 .part1 .block2 .block_body .slick-next::before, #services_sec2 .part1 .block2 .block_body .slick-prev::after, #services_sec2 .part1 .block2 .block_body .slick-prev::before {
    content: none;
  }
  #services_sec2 .part1 .block2 .block_body .slick-next {
    background: url(./../images/arrow_r.png) no-repeat;
    background-size: contain;
    right: -8vw;
  }
  #services_sec2 .part1 .block2 .block_body .slick-prev {
    background: url(./../images/arrow_l.png) no-repeat;
    background-size: contain;
    left: -8vw;
  }
  #services_sec2 .part1 .block2 .block_body .slicker_part h5 {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 6vw;
  }
  #services_sec2 .part1 .block2 .block_body .slicker_part .piece .img_block > img {
    margin-bottom: 28px;
  }
  #services_sec2 .part1 .block2 .block_body .slicker_part .piece .img_block dl {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2vw;
  }
  #services_sec2 .part1 .block2 .block_body .slicker_part .piece .img_block dl dt {
    width: 77px;
    font-size: 0.85rem;
  }
  #services_sec2 .part1 .block2 .block_body .slicker_part .piece .img_block dl dd {
    line-height: 1.7;
    font-size: 0.85rem;
  }
  #services_sec2 .part1 .block2 .block_body .slicker_part .piece .flex_body {
    font-size: 0.95rem;
    line-height: 1.9;
    padding-left: 0;
    padding-top: 6vw;
  }
  #services_sec2 .part1 .block2 .block_body .slick-dots {
    bottom: -20vw;
  }
  #services_sec2 .part1 .block2 .block_body .slick-dots li {
    height: 6px;
  }
  #services_sec2 .part1 .block2 .block_body .slick-dots li button {
    width: 40px;
    height: 6px;
    border-radius: 6px;
    background: #a29888;
  }
  #services_sec2 .part1 .block2 .block_body .slick-dots li button::after, #services_sec2 .part1 .block2 .block_body .slick-dots li button::before {
    content: none;
  }
  #services_sec2 .part1 .block2 .block_body .slick-dots li.slick-active button {
    background: #ff6400;
  }
  #services_sec2 .part2 {
    width: 90vw;
    margin: auto;
  }
  #services_sec2 .part2 .block3 {
    padding-top: 10vw;
  }
  #services_sec2 .part2 .block3 h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-bottom: 6vw;
  }
  #services_sec2 .part2 .block3 h4 .text {
    display: inline-flex;
    flex: 1;
  }
  #services_sec2 .part2 .block3 > p {
    font-size: 0.95rem;
  }
  #services_sec2 .part2 .block3 .block_body ul li {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4vw;
    line-height: 1.7;
  }
  #services_sec2 .part2 .block3 .block_body ul li .feature {
    margin-right: 4vw;
    display: flex;
    padding: 2vw;
    background: #ff6400;
    color: white;
    border-radius: 50%;
    font-weight: bold;
    width: 16vw;
    height: 16vw;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  #services_sec2 .part2 .block3 .block_body ul li .text {
    display: inline-flex;
    flex: 1;
  }
  #services_sec2 .part2 .block4 {
    padding-top: 10vw;
  }
  #services_sec2 .part2 .block4 h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-bottom: 10px;
  }
  #services_sec2 .part2 .block4 > p {
    font-size: 0.95rem;
    line-height: 1.7;
  }
  #services_sec2 .part2 .block4 .block_body {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #services_sec2 .part2 .block4 .block_body .piece {
    max-width: 48%;
    margin-right: 2%;
    border: 2px solid #ff6400;
    border-radius: 10px;
    margin-top: 2vw;
    position: relative;
  }
  #services_sec2 .part2 .block4 .block_body .piece img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  #services_sec2 .part2 .block4 .block_body .piece .step {
    position: absolute;
    background: #ff6400;
    top: 25px;
    left: -5px;
    color: white;
    z-index: 100;
    width: 55px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    height: 26px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #services_sec2 .part2 .block4 .block_body .piece .step::after {
    border-top: 13px solid transparent;
    border-left: 10px solid #ff6400;
    border-bottom: 13px solid transparent;
    content: "";
    position: absolute;
    right: -10px;
  }
  #services_sec2 .part2 .block4 .block_body .piece2 {
    max-width: 98%;
    margin-right: 2%;
  }
  #services_sec2 .part2 .block4 .block_body .piece .piece_body {
    padding: 20px;
  }
  #services_sec2 .part2 .block4 .block_body .piece .piece_body h5 {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #ff6400;
    margin-bottom: 10px;
  }
  #services_sec2 .part2 .block4 .block_body .piece .piece_body p {
    font-size: 0.85rem;
    display: flex;
  }
  #services_sec2 .part2 .block4 .block_body .piece .piece_body .piece_group {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  #services_sec2 .part2 .block4 .block_body .piece .piece_body .piece_group ul {
    flex: 1;
  }
  #services_sec2 .part2 .block4 .block_body .piece .piece_body .piece_group ul li {
    font-size: 0.85rem;
  }
  #services_sec2 .part2 .block5 {
    padding-top: 8vw;
  }
  #services_sec2 .part2 .block5 h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-bottom: 2vw;
  }
  #services_sec2 .part2 .block5 .block_body {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #services_sec2 .part2 .block5 .block_body .piece {
    margin-right: 0;
    background: #f5f3ee;
    margin-top: 4vw;
    display: inline-flex;
    width: 43vw;
    height: 43vw;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 5px solid #ff6400;
    font-size: 1.05rem;
    font-weight: bold;
    text-align: center;
    color: #ff6400;
  }
  #services_sec2 .part2 .block5 .block_body ul li {
    font-size: 1rem;
    line-height: 1.7;
    padding: 12px 0;
    border-bottom: 1px dotted #645845;
  }
  #services_sec2 .part3 {
    background: #f5f3ee;
    padding-bottom: 10vw;
    margin-top: 10vw;
    padding-top: 10vw;
  }
  #services_sec2 .part3 .block1 {
    width: 90vw;
    margin: auto;
    background: white;
    border-radius: 10px;
    padding: 4vw;
    margin-bottom: 6vw;
  }
  #services_sec2 .part3 .block1 h4 {
    text-align: center;
    margin-bottom: 5vw;
  }
  #services_sec2 .part3 .block1 ul li {
    font-size: 1.05rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 2vw;
  }
  #services_sec2 .part3 .block1 ul li span {
    margin-left: 2vw;
  }
  #services_sec2 .part3 .block6 {
    width: 90vw;
    margin: auto;
    padding-bottom: 10vw;
  }
  #services_sec2 .part3 .block6 h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-bottom: 3vw;
  }
  #services_sec2 .part3 .block6 .block_body {
    background: white;
    padding: 4vw;
    border-radius: 10px;
  }
  #services_sec2 .part3 .block6 .block_body ul li {
    font-size: 1.05rem;
    line-height: 1.8;
  }
  #services_sec2 .part3 .block7 {
    width: 90vw;
    margin: auto;
  }
  #services_sec2 .part3 .block7 h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-bottom: 3vw;
  }
  #services_sec2 .part3 .block7 .block_body {
    background: white;
    padding: 4vw;
    border-radius: 2vw;
  }
  #services_sec2 .part3 .block7 .block_body h5 {
    color: white;
    width: 100%;
    border-radius: 4vw;
    background-color: #ff6400;
    font-size: 1.05rem;
    font-weight: bold;
    height: 16vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 4vw;
    margin-bottom: 5vw;
  }
  #services_sec2 .part3 .block7 .block_body h5.second {
    background-color: #645845;
  }
  #services_sec2 .part3 .block7 .block_body dl {
    margin-bottom: 5vw;
  }
  #services_sec2 .part3 .block7 .block_body dl dt {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  #services_sec2 .part3 .block7 .block_body dl dt span {
    margin-left: 2vw;
    display: inline-block;
    font-size: 1.05rem;
    font-weight: bold;
    color: #ff6400;
  }
  #services_sec2 .part3 .block7 .block_body dl dd {
    padding-left: 6vw;
    font-size: 1rem;
    padding-bottom: 5px;
  }
  #services_sec2 .part3 .block7 .block_body ul {
    padding-left: 6vw;
  }
  #services_sec2 .part3 .block7 .block_body ul li {
    list-style: "・";
    font-size: 1.05rem;
  }
  #services_sec2 .part3 .block8 {
    width: 90vw;
    margin: auto;
    padding-bottom: 10vw;
    padding-top: 10vw;
  }
  #services_sec2 .part3 .block8 h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-bottom: 3vw;
  }
  #services_sec2 .part3 .block8 .block_body {
    background: white;
    padding: 4vw;
  }
  #services_sec2 .part3 .block8 .block_body > p {
    font-size: 1.05rem;
  }
  #services_sec2 .part3 .block8 .block_body ul {
    padding: 4vw;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }
  #services_sec2 .part3 .block8 .block_body ul li {
    display: block;
    max-width: 400px;
    width: 100%;
    margin-bottom: 30px;
  }
  #services_sec2 .part3 .block8 .block_body ul li .img_wrapper {
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
  }
  #services_sec2 .part3 .block8 .block_body ul li .img_wrapper img {
    width: 100%;
    height: 50vw;
    object-fit: cover;
    object-position: center;
  }
  #services_sec2 .part3 .block8 .block_body ul li > p {
    font-size: 0.75rem;
    text-align: center;
  }
  #services_sec2 .part3 .block8 .block_body ul li:last-child {
    margin-bottom: 0;
  }
  #services_sec3 {
    background: #f5f3ee;
    padding: 10vw 0 18vw;
  }
  #services_sec3 .circle_mark {
    display: inline-block;
    width: 4vw;
    height: 4vw;
    margin-right: 10px;
    border: 6px solid #ff6400;
    border-radius: 50%;
  }
  #services_sec3 .part1 {
    width: 90vw;
    padding: 8vw;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  #services_sec3 .part1 small {
    line-height: 1.7;
  }
  #services_sec3 .part1 .block {
    width: 100%;
    margin-bottom: 0;
    border-bottom: 1px dotted black;
    padding: 4vw 4vw;
    border-right: none;
  }
  #services_sec3 .part1 .block:nth-child(odd) {
    border-right: none;
  }
  #services_sec3 .part1 .block2 {
    width: 100%;
    margin-bottom: 3vw;
    border-bottom: none;
    padding-bottom: 0;
  }
  #services_sec3 .part1 .block2 p {
    font-weight: normal !important;
    line-height: 1.7;
  }
  #services_sec3 .part1 .block3 {
    border-top: 2px solid #645845;
    width: 100%;
    padding-top: 3vw;
    border-bottom: none;
    padding-bottom: 0;
  }
  #services_sec3 .part1 .block3 p {
    font-weight: normal !important;
    line-height: 1.7;
  }
  #services_sec3 .part1 .block h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 1.05rem;
    margin-bottom: 4vw;
  }
  #services_sec3 .part1 .block h4 img {
    margin-right: 3vw;
    max-width: 7vw;
  }
  #services_sec3 .part1 .block .block_body > p {
    font-size: 1rem;
  }
  #services_sec3 .part1 .block .block_body > p.flex_body {
    display: block;
  }
  #services_sec3 .part1 .block .block_body h5 {
    font-size: 1rem;
    font-weight: normal;
  }
  #services_sec3 .part1 .block .block_body small {
    font-size: 0.8rem;
    line-height: 1.4;
  }
  #services_sec3 .part1 .block .block_body ul li {
    font-size: 1rem;
  }
  #services_sec3 .part1 .block .block_body.flex_multi .flex_body {
    width: 100%;
    padding: 3vw;
  }
  #services_sec3 .part1 .block .block_body.flex_multi .flex_body:nth-child(odd) {
    border-right: none;
    border-bottom: 1px dotted black;
  }
  #services_sec3 .part2 {
    width: 90vw;
    margin: auto;
  }
  #services_sec3 .part2 .voice_block {
    padding-top: 10vw;
  }
  #services_sec3 .part2 .voice_block h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.4rem;
    padding-bottom: 4vw;
  }
  #services_sec3 .part2 .voice_block .block_body {
    background: white;
    padding: 8vw 8vw 18vw;
    border-radius: 10px;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-next, #services_sec3 .part2 .voice_block .block_body .slick-prev {
    width: 8vw;
    height: 8vw;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-next::after, #services_sec3 .part2 .voice_block .block_body .slick-next::before, #services_sec3 .part2 .voice_block .block_body .slick-prev::after, #services_sec3 .part2 .voice_block .block_body .slick-prev::before {
    content: none;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-next {
    background: url(./../images/arrow_r.png) no-repeat;
    background-size: contain;
    right: -8vw;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-prev {
    background: url(./../images/arrow_l.png) no-repeat;
    background-size: contain;
    left: -8vw;
  }
  #services_sec3 .part2 .voice_block .block_body .slicker_part h5 {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 6vw;
  }
  #services_sec3 .part2 .voice_block .block_body .slicker_part .img_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6vw;
    flex-direction: column;
  }
  #services_sec3 .part2 .voice_block .block_body .slicker_part .img_block img {
    border-radius: 10px;
  }
  #services_sec3 .part2 .voice_block .block_body .slicker_part .piece {
    padding: 6vw 0;
    border-bottom: 1px dotted #645845;
  }
  #services_sec3 .part2 .voice_block .block_body .slicker_part .piece .flex_body {
    font-size: 0.95rem;
    line-height: 1.9;
  }
  #services_sec3 .part2 .voice_block .block_body .slicker_part .piece .flex_body h6 {
    font-size: 1.1rem;
    font-weight: bold;
  }
  #services_sec3 .part2 .voice_block .block_body .slicker_part .piece.flex_img_left .flex_body {
    padding-left: 0;
    padding-top: 6vw;
  }
  #services_sec3 .part2 .voice_block .block_body .slicker_part .piece.flex_img_right .flex_body {
    padding-right: 0;
    padding-right: 0;
    padding-top: 6vw;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-dots {
    bottom: -10vw;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-dots li {
    height: 6px;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-dots li button {
    width: 40px;
    height: 6px;
    border-radius: 6px;
    background: #a29888;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-dots li button::after, #services_sec3 .part2 .voice_block .block_body .slick-dots li button::before {
    content: none;
  }
  #services_sec3 .part2 .voice_block .block_body .slick-dots li.slick-active button {
    background: #ff6400;
  }
  #services_brand_list .part1 {
    width: 90vw;
    margin: auto;
    padding: 10vw 0;
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    background: white;
    flex-wrap: wrap;
  }
  #services_brand_list .part1 h2 {
    width: 100%;
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-start;
    font-size: 0.9rem;
    align-items: center;
  }
  #services_brand_list .part1 h2 span {
    margin-left: 10px;
  }
  #services_brand_list .part1 .block {
    width: 50%;
    height: 20vw;
    background: #f5f3ee;
    border-radius: 10px;
    border: 2px solid white;
  }
  #services_brand_list .part1 .block.active {
    background: #ff6400;
  }
  #services_brand_list .part1 .block.active a {
    color: white;
  }
  #services_brand_list .part1 .block a {
    font-weight: bold;
    font-size: 1rem;
    display: inline-block;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.7;
    color: #645845;
  }
  footer .part1 {
    background: #ff6400;
  }
  footer .part1 h2 {
    padding-top: 10vw;
    padding-bottom: 6vw;
    color: white;
    font-size: 1.4rem;
  }
  footer .part1 .block {
    width: 90vw;
    margin: auto;
    padding-bottom: 10vw;
  }
  footer .part1 .block a {
    display: inline-block;
    margin-bottom: 3vw;
  }
  footer .part1 .block p {
    font-size: 0.9rem;
    color: white;
  }
  footer .part2 h3 {
    text-align: center;
    padding-top: 10vw;
    padding-bottom: 6vw;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  footer .part2 h3::before {
    content: "";
    height: 1.6rem;
    width: 3px;
    background: #645845;
    margin-right: 2rem;
    transform: rotate(-30deg);
  }
  footer .part2 h3::after {
    content: "";
    height: 1.6rem;
    width: 3px;
    background: #645845;
    margin-left: 2rem;
    transform: rotate(30deg);
  }
  footer .part2 .block {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10vw;
  }
  footer .part2 .block a {
    display: block;
    margin: 0 2vw;
  }
  footer .part3 {
    width: 90vw;
    padding-bottom: 10vw;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  footer .part3 .block {
    flex: none;
    width: 50%;
    margin-bottom: 5vw;
    padding: 2vw 4vw;
  }
  footer .part3 .block:nth-child(2n) {
    border-right: none;
  }
  footer .part3 .block h3 {
    font-size: 0.7rem;
    font-weight: bold;
    margin-bottom: 4vw;
  }
  footer .part3 .block ul li {
    font-size: 0.7rem;
  }
  footer .part4 p {
    font-size: 0.8rem;
    padding: 5vw 0;
  }
  #partner_sec_fv {
    height: 41vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #partner_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90vw;
    transform: translate(-50%, -50%);
  }
  #partner_sec1 {
    background-color: #f5f3ee;
  }
  #partner_sec1 h2 {
    padding-top: 10vw;
    padding-bottom: 3vw;
  }
  #partner_sec1 > p {
    width: 90vw;
    margin: auto;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 6vw;
  }
  #partner_sec1 .part1 {
    width: 90vw;
    margin: auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 6vw;
    padding-bottom: 10vw;
  }
  #partner_sec1 .part1 .block {
    background: white;
    padding: 6vw 4vw 13vw;
    margin-bottom: 3vw;
    max-width: 100%;
    margin-right: 0;
    border-radius: 10px;
    position: relative;
  }
  #partner_sec1 .part1 .block:nth-child(4n) {
    margin-right: 0;
  }
  #partner_sec1 .part1 .block img {
    width: 100%;
    height: 20vw;
    object-fit: contain;
    object-position: center;
  }
  #partner_sec1 .part1 .block h3 {
    padding-top: 6vw;
    font-size: 0.9rem;
    text-align: center;
    padding-bottom: 3vw;
  }
  #partner_sec1 .part1 .block p.description {
    font-size: 0.8rem;
    font-weight: bold;
  }
  #partner_sec1 .part1 .block p.project {
    padding-top: 0.9rem;
    font-size: 0.7rem;
  }
  #partner_sec1 .part1 .block .btn_group {
    text-align: right;
  }
  #partner_sec1 .part1 .block .btn_group a {
    font-size: 0.8rem;
  }
  #news_sec_fv {
    height: 41vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #news_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90vw;
    transform: translate(-50%, -50%);
  }
  #news_sec1 {
    background-color: #f5f3ee;
    padding-top: 10vw;
    padding-bottom: 10vw;
  }
  #news_sec1 .part1 {
    width: 90vw;
    margin: auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #news_sec1 .part1 .block {
    background: white;
    padding: 5vw;
    margin-bottom: 5vw;
    width: 100%;
    margin-right: 0;
    border-radius: 10px;
  }
  #news_sec1 .part1 .block:nth-child(3n) {
    margin-right: 0;
  }
  #news_sec1 .part1 .block .block_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #news_sec1 .part1 .block .block_header span {
    display: inline-block;
  }
  #news_sec1 .part1 .block .block_header span.category {
    font-size: 0.95rem;
    padding: 0 4px;
    color: white;
  }
  #news_sec1 .part1 .block .block_header span.category.news_category {
    background: #ff6400;
  }
  #news_sec1 .part1 .block .block_header span.category.events_category {
    background: #21dc21;
  }
  #news_sec1 .part1 .block .block_header span.category.media_category {
    background: #3866aa;
  }
  #news_sec1 .part1 .block .block_header span:last-child {
    font-size: 0.8rem;
  }
  #news_sec1 .part1 .block .block_header .news_category {
    font-size: 0.95rem;
  }
  #news_sec1 .part1 .block h3 {
    font-size: 1rem;
    padding: 4vw 0;
  }
  #news_sec1 .part1 .block p {
    font-size: 0.8rem;
  }
  #news_sec1 .part1 .block .block_footer {
    padding-top: 5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #news_sec1 .part1 .block .block_footer .tag_block {
    flex: 1;
  }
  #news_sec1 .part1 .block .block_footer .tag_block span {
    font-size: 0.8rem;
  }
  #news_sec1 .part1 .block .block_footer .new_mark {
    margin-left: 4vw;
    color: #ff6400;
    border: 1px solid #ff6400;
    border-radius: 50%;
    display: inline-flex;
    width: 10vw;
    height: 10vw;
    align-items: center;
    justify-content: center;
  }
  #misomaga_sec_fv {
    height: 17.2vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #misomaga_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    width: 90vw;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #works_sec_fv {
    height: 35.4vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #works_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    width: 90vw;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #misomaga_sec1 {
    background: #f5f3ee;
    padding-bottom: 10vw;
  }
  #misomaga_sec1 .fixedcontainer {
    background: white;
    border-radius: 10px;
    padding: 4vw 4vw;
    width: 90vw;
    margin: auto;
  }
  #misomaga_sec1 .part1 {
    padding-bottom: 8vw;
  }
  #misomaga_sec1 .part1 h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4vw;
  }
  #misomaga_sec1 .part1 h3 span {
    margin-left: 8px;
    font-size: 1rem;
  }
  #misomaga_sec1 .part1 .part_block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #misomaga_sec1 .part1 .block {
    margin-right: 0;
    margin-bottom: 5vw;
    width: 100%;
  }
  #misomaga_sec1 .part1 .block:nth-child(3n) {
    margin-right: 0;
  }
  #misomaga_sec1 .part1 .block > img {
    width: 100%;
    height: 30vw;
    object-fit: contain;
  }
  #misomaga_sec1 .part1 .block h4 {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    margin-top: 2vw;
  }
  #misomaga_sec1 .part2 h3 {
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 25px;
  }
  #misomaga_sec1 .part2 .part_block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  #misomaga_sec1 .part2 .part_block span {
    font-size: 1rem;
    font-weight: bold;
    color: #ff6400;
    display: inline-block;
    padding: 1vw 3vw;
    border: 1px solid #ff6400;
    border-radius: 2vw;
    line-height: 1;
    margin-right: 3vw;
    margin-bottom: 3vw;
  }
  #misomaga_sec2 {
    background: #f5f3ee;
    padding-top: 8vw;
  }
  #misomaga_sec2 .part1 {
    width: 90vw;
    margin: auto;
    margin-bottom: 6vw;
    border-radius: 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
  #misomaga_sec2 .part1 a {
    display: flex;
    flex: 1;
    font-size: 0.9rem;
    height: 10vw;
    align-items: center;
    justify-content: center;
    background: white;
    color: #ff6400;
    line-height: 1;
    font-weight: bold;
  }
  #misomaga_sec2 .part1 a.active {
    background: #ff6400;
    color: white;
    font-weight: bold;
    line-height: 1;
  }
  #misomaga_sec2 .part2 {
    width: 90vw;
    margin: auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 10vw;
  }
  #misomaga_sec2 .part2 .block {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 5vw;
    margin-right: 0;
  }
  #misomaga_sec2 .part2 .block > img {
    width: 100%;
    height: 50vw;
  }
  #misomaga_sec2 .part2 .block .block_body {
    background: white;
    padding: 23px 30px;
  }
  #misomaga_sec2 .part2 .block .block_body .block_header {
    text-align: left;
  }
  #misomaga_sec2 .part2 .block .block_body .block_header span {
    display: inline-block;
    padding: 1vw 2.5vw;
    border: 1px solid #ff6400;
    color: #ff6400;
    font-size: 0.9rem;
    line-height: 1;
    border-radius: 15px;
  }
  #misomaga_sec2 .part2 .block .block_body p {
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 4vw;
    padding-top: 3vw;
  }
  #misomaga_sec2 .part2 .block .block_body .block_footer span {
    font-size: 0.8rem;
  }
  #words_sec_fv {
    height: 41vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #words_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90vw;
    transform: translate(-50%, -50%);
  }
  #words_sec1 {
    background: #f5f3ee;
    padding-top: 9vw;
    padding-bottom: 10vw;
  }
  #words_sec1 .part1 {
    width: 90vw;
    margin: auto;
    background: white;
    border-radius: 10px;
    padding: 6vw 5vw;
  }
  #words_sec1 .part1 h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #words_sec1 .part1 h3 span {
    margin-left: 2vw;
    font-size: 0.9rem;
    font-weight: bold;
  }
  #words_sec1 .part1 .part_block {
    padding: 0 4vw;
    padding-top: 4vw;
  }
  #words_sec1 .part1 .part_block a {
    display: inline-flex;
    width: 8vw;
    height: 8vw;
    border-radius: 8px;
    border: 2px solid #ff6400;
    color: #ff6400;
    align-items: center;
    justify-content: center;
    margin-right: 2vw;
    margin-bottom: 2vw;
    font-size: 1.2rem;
  }
  #words_sec1 .part2 {
    width: 90vw;
    margin: auto;
    padding-top: 6vw;
  }
  #words_sec1 .part2 h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ff6400;
    padding-bottom: 2vw;
  }
  #words_sec1 .part2 .block {
    margin-top: 5vw;
    background: white;
    padding: 6vw 4vw;
    border-radius: 10px;
  }
  #words_sec1 .part2 .block_body {
    padding: 0 0;
  }
  #words_sec1 .part2 .block_body dl {
    padding: 4vw 0;
    display: flex;
    border-bottom: 1px solid black;
  }
  #words_sec1 .part2 .block_body dl:last-child {
    border-bottom: none;
  }
  #words_sec1 .part2 .block_body dl dt {
    width: 20%;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 2vw;
  }
  #words_sec1 .part2 .block_body dl dd {
    flex: 1;
    font-size: 1rem;
  }
  #about_sec1 {
    background: #f5f3ee;
    padding-top: 10vw;
    padding-bottom: 10vw;
  }
  #about_sec1 h2 {
    padding-bottom: 8vw;
  }
  #about_sec1 .part1 {
    width: 90vw;
    margin: auto;
    background: white;
    border-radius: 1.5vw;
    padding: 8vw 4vw;
  }
  #about_sec1 .part1 table {
    width: 100%;
  }
  #about_sec1 .part1 table tr th {
    font-weight: bold;
    font-size: 1rem;
    width: 30%;
    border-bottom: 1px solid #f2eee8;
    padding: 3vw 3vw;
    text-align: left;
    vertical-align: top;
  }
  #about_sec1 .part1 table tr td {
    font-size: 1rem;
    border-bottom: 1px solid #f2eee8;
    padding: 3vw 0;
  }
  #about_sec1 .part1 table tr td ul {
    padding: 10px 0;
  }
  #contact_sec1 {
    padding: 10vw 0 10vw;
    background: #f5f3ee;
  }
  #contact_sec1 p {
    font-size: 1rem;
    text-align: center;
  }
  #contact_sec2 .part1 {
    padding: 0 6vw 0;
    border: 2px solid #f5f3ee;
    background: white;
    margin-top: 0;
    border-radius: 10px;
  }
  #contact_sec2 .part1 .block {
    border-bottom: 1px solid #f2eee8;
    padding: 4vw 0;
  }
  #contact_sec2 .part1 .block:last-child {
    border-bottom: none;
  }
  #contact_sec2 .part1 .block h3 {
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #contact_sec2 .part1 .block h3 span {
    font-size: 1.2rem;
    color: #ff6400;
    font-weight: bold;
  }
  #contact_sec2 .part1 .block h3 span:first-child {
    color: white;
    background: #ff6400;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  #contact_sec2 .part1 .block .block_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
  }
  #contact_sec2 .part1 .block .block_body p {
    text-align: left;
    padding-top: 4vw;
    font-size: 0.9rem;
    padding-bottom: 3vw;
  }
  #contact_sec2 .part2 {
    padding-top: 10vw;
  }
  #contact_sec3 {
    padding-top: 10vw;
    background: #f5f3ee;
    padding-bottom: 10vw;
  }
  #contact_sec3 h2 {
    padding-bottom: 7vw;
  }
  #contact_sec3 .part1 {
    background: white;
    border-radius: 10px;
    padding: 4vw;
  }
  #contact_sec3 .part1 h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 4vw;
  }
  #contact_sec3 .part1 h3 span {
    font-size: 1.2rem;
    margin-left: 5px;
    font-weight: bold;
  }
  #contact_sec3 .part1 > p {
    font-size: 0.9rem;
    padding-bottom: 4vw;
  }
  #contact_sec3 .part1 .block {
    display: flex;
    padding: 0 3vw;
    padding-top: 4vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 2px solid #f5f3ee;
  }
  #contact_sec3 .part1 .block .piece {
    padding: 2vw 0;
  }
  #contact_sec3 .part1 .block .piece:first-child {
    flex: 1;
    border-right: none;
  }
  #contact_sec3 .part1 .block .piece:last-child {
    padding-left: 0;
  }
  #contact_sec3 .part1 .block .piece p {
    font-size: 0.9rem;
    padding-top: 8px;
  }
  #contact_sec_fv {
    height: 41vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #contact_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    width: 90vw;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #faq_sec_fv {
    height: 41vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #faq_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90vw;
    transform: translate(-50%, -50%);
  }
  #about_sec_fv {
    height: 41vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #about_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    width: 90vw;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #faq_sec1 {
    background: #f5f3ee;
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  #faq_sec1 p {
    font-size: 1rem;
    text-align: left;
    width: 90vw;
    margin: auto;
  }
  #faq_sec1 .part1 {
    width: 90vw;
    margin: auto;
    margin-top: 8vw;
  }
  #faq_sec1 .part1 #tabs {
    border: none;
    padding: 0;
    background: none;
  }
  #faq_sec1 .part1 #tabs ul {
    display: flex;
    padding: 0;
    border: none;
    margin: 0;
    margin-bottom: 5vw;
    border-radius: 10px;
    overflow: hidden;
  }
  #faq_sec1 .part1 #tabs ul li {
    padding: 0;
    margin: 0;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    height: 65px;
    border: none;
  }
  #faq_sec1 .part1 #tabs ul li a {
    font-size: 0.9rem;
    font-weight: bold;
    color: #ff6400;
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  #faq_sec1 .part1 #tabs ul li.ui-state-active {
    background: #ff6400;
  }
  #faq_sec1 .part1 #tabs ul li.ui-state-active a {
    color: white;
  }
  #faq_sec1 .part1 #tabs .content {
    padding: 0;
  }
  #faq_sec1 .part1 #tabs .content .block dl {
    margin-bottom: 4vw;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt {
    background: white;
    height: 20vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    padding: 0 5vw;
    font-size: 0.9rem;
    font-weight: bold;
    position: relative;
    transition: all .4s;
    cursor: pointer;
    padding-right: 10vw;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt .plus {
    position: absolute;
    right: 4vw;
    width: 6vw;
    height: 6vw;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt .plus::before {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    background: black;
    top: 14px;
    right: 0;
    transition: all .4s;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt .plus::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 30px;
    background: black;
    top: 0px;
    right: 14px;
    transform: rotate(0);
    transition: all .4s;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt .red_mark {
    margin-right: 3vw;
    background: #ff6400;
    display: inline-flex;
    width: 8vw;
    height: 8vw;
    align-items: center;
    justify-content: center;
    font-size: 1.05rem;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    font-family: 'Jura', sans-serif;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt .text {
    flex: 1;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt.active {
    background: #ff6400;
    transition: all .4s;
    color: white;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt.active .red_mark {
    background: white;
    color: #ff6400;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt.active .plus::before {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    background: white;
    top: 14px;
    right: 0;
    transition: all .4s;
  }
  #faq_sec1 .part1 #tabs .content .block dl dt.active .plus::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 30px;
    background: white;
    top: 0px;
    right: 14px;
    transform: rotate(90deg);
    transition: all .4s;
  }
  #faq_sec1 .part1 #tabs .content .block dl dd {
    background: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
    padding: 3vw 5vw;
    font-size: 0.9rem;
    display: none;
  }
  #faq_sec1 .part1 #tabs .content .block dl dd .blue_mark {
    margin-right: 3vw;
    background: #ff6400;
    display: inline-flex;
    width: 8vw;
    height: 8vw;
    align-items: center;
    justify-content: center;
    font-size: 1.05rem;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    font-family: 'Jura', sans-serif;
    vertical-align: top;
  }
  #faq_sec1 .part1 #tabs .content .block dl dd .detail {
    flex: 1;
    display: inline-block;
    width: 80%;
  }
  #members_sec_fv {
    height: 41vw;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    background-color: #cfcbc5;
  }
  #members_sec_fv h2 {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90vw;
    transform: translate(-50%, -50%);
  }
  #members_sec1 {
    background: #f5f3ee;
    padding-top: 10vw;
    padding-bottom: 8vw;
  }
  #members_sec1 .part1 {
    width: 90vw;
    margin: auto;
    background: white;
    border-radius: 10px;
    padding: 8vw 5vw;
  }
  #members_sec1 .part1 h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #members_sec1 .part1 h3 span {
    font-size: 0.9rem;
    padding-left: 3vw;
  }
  #members_sec1 .part1 .part_body {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-top: 5vw;
    flex-wrap: wrap;
  }
  #members_sec1 .part1 .part_body .block {
    color: #645845;
    width: 45%;
    margin: 3vw 1vw;
  }
  #members_sec1 .part1 .part_body .block p {
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
    padding-top: 3vw;
  }
  #members_sec1 .part1 .part_body .block p span {
    font-size: 1rem;
  }
  #members_sec2 {
    padding-top: 10vw;
  }
  #members_sec2 .part1 {
    width: 90vw;
    margin: auto;
  }
  #members_sec2 .part1 .member_block {
    margin-top: 10vw;
    padding-bottom: 6vw;
    position: relative;
  }
  #members_sec2 .part1 .member_block::after {
    content: none;
    display: block;
    position: absolute;
    width: calc(50vw + 430px);
    height: calc(100% - 162px);
    background: #f5f3ee;
    bottom: 0;
    left: 85px;
    z-index: -1;
  }
  #members_sec2 .part1 .member_block:nth-child(2n)::after {
    content: none;
    display: block;
    position: absolute;
    width: calc(50vw + 430px);
    height: calc(100% - 162px);
    background: #f5f3ee;
    bottom: 0;
    right: 85px;
    left: auto;
    z-index: -1;
  }
  #members_sec2 .part1 .member_block .block1 {
    align-items: flex-start;
  }
  #members_sec2 .part1 .member_block .block1 .flex_body {
    padding-left: 0;
    padding-top: 8vw;
  }
  #members_sec2 .part1 .member_block .block1 .flex_body h3 {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1;
  }
  #members_sec2 .part1 .member_block .block1 .flex_body .profile1 {
    height: initial;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    position: relative;
  }
  #members_sec2 .part1 .member_block .block1 .flex_body .profile1::after {
    content: none;
    width: 30vw;
    height: 2px;
    background: black;
    position: absolute;
    left: -50vw;
    bottom: 0;
  }
  #members_sec2 .part1 .member_block .block1 .flex_body .profile2 {
    padding-top: 5vw;
    font-size: 1rem;
    padding-right: 3vw;
    line-height: 1.8;
  }
  #members_sec2 .part1 .member_block .block1.flex_img_right .flex_body {
    padding-left: 0;
    padding-right: 0;
  }
  #members_sec2 .part1 .member_block .block1.flex_img_right .flex_body .profile1::after {
    content: none;
    width: 150px;
    height: 2px;
    background: black;
    position: absolute;
    left: auto;
    right: -100px;
    bottom: 0;
  }
  #members_sec2 .part1 .member_block h3 {
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 6vw;
  }
  #members_sec2 .part1 .member_block .block2 {
    max-width: 950px;
    margin: auto;
    border: 2px solid #f5f3ee;
    border-radius: 10px;
    background: white;
    overflow-y: scroll;
    padding: 5vw 4vw;
    max-height: 400px;
  }
  #members_sec2 .part1 .member_block .block2::-webkit-scrollbar {
    width: 3vw;
    -webkit-overflow-scrolling: touch;
    -webkit-appearance: none;
  }
  #members_sec2 .part1 .member_block .block2::-webkit-scrollbar-thumb {
    background: #ff6400;
    border-radius: 10px;
  }
  #members_sec2 .part1 .member_block .block2::-webkit-scrollbar-track {
    background: #f2eee8;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  #members_sec2 .part1 .member_block .block2 .content dl {
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
  }
  #members_sec2 .part1 .member_block .block2 .content dl dt {
    width: 10vw;
    font-family: 'kiteOne', sans-serif;
    font-size: 0.9rem;
  }
  #members_sec2 .part1 .member_block .block2 .content dl dd {
    flex: 1;
    margin-left: 4vw;
    padding-left: 4vw;
    border-left: 2px solid #f5f3ee;
  }
  #members_sec2 .part1 .member_block .block2 .content dl dd ul li {
    list-style: "・";
    font-size: 0.9rem;
  }
  #members_sec2 .part1 .member_block h3 {
    font-size: 0.9rem;
    font-weight: bold;
  }
  #members_sec2 .part1 .member_block .block3 {
    max-width: 950px;
    margin: auto;
    border: 2px solid #f5f3ee;
    border-radius: 10px;
    background: white;
    padding: 5vw 4vw;
  }
  #members_sec2 .part1 .member_block .block3 .content ul li {
    padding-top: 20px;
    font-size: 0.9rem;
    margin-left: 20px;
    list-style: '・';
  }
  #members_sec2 .part1 .member_block .block3 .content ul li a {
    color: #ff6400;
  }
  #privacy_sec1 {
    padding-bottom: 100px;
  }
  #privacy_sec1 h2 {
    max-width: 88vw;
    margin: auto;
    padding-top: 8vw;
    padding-bottom: 6vw;
    font-weight: bold;
    text-align: center;
  }
  #privacy_sec1 h2 span.nihongo {
    font-size: 1.3rem;
  }
  #privacy_sec1 .part1 {
    max-width: 88vw;
    margin: auto;
  }
  #privacy_sec1 .part1 h3 {
    padding-top: 4vw;
    padding-bottom: 2vw;
    text-align: left;
    font-weight: bold;
    font-size: 1.1rem;
  }
  #privacy_sec1 .part1 p {
    font-size: 1rem;
  }
  #contact_sec_part {
    padding-bottom: 10vw;
  }
  #contact_sec_part h2 {
    text-align: center;
    padding: 7vw 0 7vw;
  }
  #contact_sec_part .content {
    width: 90%;
    margin: auto;
  }
  #contact_sec_part .content .form-group {
    display: flex;
    margin-bottom: 3vw;
    padding-bottom: 3vw;
    border-bottom: 1px solid #f5f3ee;
    flex-direction: column;
  }
  #contact_sec_part .content .form-group label {
    font-size: 1rem;
    padding-bottom: 2rem;
  }
  #contact_sec_part .content .form-group .wpcf7-list-item {
    margin-left: 0;
    padding: 2vw 0;
  }
  #contact_sec_part .content .form-group > label {
    display: inline-flex;
    width: auto;
    font-size: 1rem;
    /* margin-bottom: 20px; */
    position: relative;
    align-items: flex-start;
  }
  #contact_sec_part .content .form-group > label .essential {
    position: absolute;
    font-size: 1rem;
    right: 20px;
    top: 15px;
    background: #ff6400;
    padding: 3px 10px;
    color: white;
    line-height: 1.5;
  }
  #contact_sec_part .content .form-group > label .unessential {
    position: absolute;
    font-size: 1rem;
    right: 20px;
    top: 15px;
    line-height: 1.5;
    background: #f2eee8;
    padding: 3px 10px;
    color: white;
    color: #645845;
  }
  #contact_sec_part .content .form-group .wpcf7-list-item.last {
    font-size: 1rem;
    align-self: center;
  }
  #contact_sec_part .content .form-group .wpcf7-list-item .wpcf7-list-item-label {
    padding-right: 3vw;
  }
  #contact_sec_part .content .form-group .wpcf7-list-item.first {
    padding-top: 7px;
  }
  #contact_sec_part .content .form-group .wpcf7-list-item.first label input {
    display: none;
  }
  #contact_sec_part .content .form-group .wpcf7-list-item.first label .wpcf7-list-item-label {
    color: #ff6400;
    font-size: 0.9rem;
    font-weight: bold;
  }
  #contact_sec_part .content .form-group .wpcf7-form-control-wrap {
    flex: 1;
  }
  #contact_sec_part .content .form-group input[type=text], #contact_sec_part .content .form-group input[type=email], #contact_sec_part .content .form-group input[type=tel], #contact_sec_part .content .form-group select, #contact_sec_part .content .form-group textarea {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    background-color: #edeeee;
    border: none;
    padding-left: 20px;
    color: #363636;
    font-size: 15px;
  }
  #contact_sec_part .content .form-group input[type=checkbox] {
    background-color: #f5f3ee;
    border: none;
    outline: none;
  }
  #contact_sec_part .content .form-group textarea {
    height: 120px;
  }
  #contact_sec_part .content .wpcf7-list-item.first.last {
    font-size: 13px;
    align-self: center;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  #contact_sec_part .content .submit_btn {
    text-align: center;
  }
  #contact_sec_part .content .submit_btn .ajax-loader {
    display: none;
  }
  #contact_sec_part .content .submit_btn input {
    background: #221815;
    width: 300px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    background: url(./../images/contact.png);
    background-size: cover;
  }
  #contact_sec_part .content .submit_btn input:hover {
    opacity: 0.8;
  }
  .breadcrumb {
    width: 90vw;
    margin: auto;
    padding-top: 4vw;
  }
  .breadcrumb p {
    font-size: 0.8rem;
  }
  .breadcrumb p a {
    font-size: 0.8rem;
  }
  .breadcrumb p .current {
    color: #ff6400;
    font-size: 0.8rem;
  }
  #article_sec1 .wrapper {
    width: 90vw;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 8vw;
  }
  #article_sec1 .wrapper .content_left {
    width: 100%;
  }
  #article_sec1 .wrapper .content_left h2 {
    font-size: 1.4rem;
    text-align: left;
  }
  #article_sec1 .wrapper .content_left .column_detail_date {
    display: flex;
    justify-content: space-between;
    padding: 3vw 0;
    width: 100%;
  }
  #article_sec1 .wrapper .content_left .column_detail_date .keywords {
    display: flex;
  }
  #article_sec1 .wrapper .content_left .column_detail_date .keywords .term {
    display: inline-block;
    border: 1px solid #ff6400;
    padding: 3px 1.2vw;
    border-radius: 2vw;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1;
    color: #ff6400;
    margin-right: 1.2vw;
  }
  #article_sec1 .wrapper .content_left .column_detail_date .date {
    font-size: 0.9rem;
    font-family: 'kiteOne', sans-serif;
  }
  #article_sec1 .wrapper .content_left .sns_btns {
    display: flex;
  }
  #article_sec1 .wrapper .content_left .sns_btns a {
    display: flex;
    flex: 1;
    height: 12vw;
    justify-content: center;
    align-items: center;
  }
  #article_sec1 .wrapper .content_left .sns_btns a.fb_btn {
    background: #27438a;
  }
  #article_sec1 .wrapper .content_left .sns_btns a.twitter_btn {
    background: #279bf1;
  }
  #article_sec1 .wrapper .content_left .sns_btns a.line_btn {
    background: #18b705;
  }
  #article_sec1 .wrapper .content_left .content_detail {
    padding: 6vw 0;
  }
  #article_sec1 .wrapper .content_left .sub_ttl {
    font-size: 0.9rem;
    font-weight: bold;
    padding-bottom: 3vw;
  }
  #article_sec1 .wrapper .content_left .page_btns {
    display: flex;
    padding: 4vw 0;
    border-top: 2px solid #a29888;
    border-bottom: 2px solid #a29888;
    margin-top: 5vw;
    margin-bottom: 8vw;
  }
  #article_sec1 .wrapper .content_left .page_btns .prev_page {
    flex: 1;
    font-size: 0.9rem;
    color: #a29888;
    text-align: left;
    font-family: 'Jura', sans-serif;
    display: block;
    font-weight: bold;
  }
  #article_sec1 .wrapper .content_left .page_btns .list_top {
    flex: 1;
    font-size: 0.9rem;
    color: #a29888;
    text-align: center;
    display: block;
    font-weight: bold;
  }
  #article_sec1 .wrapper .content_left .page_btns .next_page {
    flex: 1;
    font-family: 'Jura', sans-serif;
    font-size: 0.9rem;
    font-weight: bold;
    color: #a29888;
    text-align: right;
    display: block;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper {
    padding-bottom: 2vw;
    border-bottom: 2px solid #a29888;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper h2 {
    font-size: 1.2rem;
    font-weight: bold;
    border-left: 5px solid #ff6400;
    padding-left: 3vw;
    margin-bottom: 4vw;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block {
    width: 48%;
    margin-right: 0;
    margin-bottom: 4vw;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block:nth-child(3n) {
    margin-right: 0;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a {
    display: block;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .content_pickup_img {
    width: 100%;
    height: 20vw;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .content_pickup_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .date {
    font-size: 0.8rem;
    font-family: "KiteOne", sans-serif;
    padding: 15px 0;
    line-height: 1;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .content_pickup_title {
    font-size: 0.9rem;
    font-weight: bold;
  }
  #article_sec1 .wrapper .content_left .recommend_columns_wrapper .recomment_columns .block a .content_terms_part {
    font-size: 0.8rem;
    text-align: right;
    padding-top: 10px;
  }
  #article_sec1 .wrapper .content_left .btn_group {
    display: flex;
    padding-top: 6vw;
    padding-bottom: 10vw;
    justify-content: space-between;
    flex-direction: column;
  }
  #article_sec1 .wrapper .content_left .btn_group a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1rem;
    font-weight: bold;
  }
  #article_sec1 .wrapper .content_left .btn_group a.btn1 {
    background: #645845;
    border-radius: 2vw;
    height: 17vw;
    margin-bottom: 4vw;
  }
  #article_sec1 .wrapper .content_left .btn_group a.btn2 {
    border-radius: 2vw;
    height: 17vw;
    background: #ff6400;
    text-align: center;
  }
  #article_sec1 .wrapper .content_right {
    width: 100%;
  }
  #article_sec1 .wrapper .content_right .content_pickup h3 {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 2vw 3vw;
    line-height: 1;
    border-left: 5px solid #ff6400;
    margin-bottom: 4vw;
  }
  #article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block {
    padding: 2vw 0;
    border-bottom: 1px solid #a29888;
  }
  #article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a .piece {
    width: 100%;
  }
  #article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a .piece img {
    width: 100%;
    height: 30vw;
    object-fit: cover;
  }
  #article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a .piece .recomment_column_date {
    font-size: 0.8rem;
    font-family: "KiteOne", sans-serif;
    padding-top: 8px;
  }
  #article_sec1 .wrapper .content_right .content_pickup .content_pickup_list .block a .recomment_column_ttl {
    font-size: 1rem;
    padding-left: 3vw;
  }
  #article_sec1 .wrapper .content_right .content_category {
    padding-top: 6vw;
  }
  #article_sec1 .wrapper .content_right .content_category h3 {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 1.2vw 2vw;
    line-height: 1;
    border-left: 5px solid #ff6400;
    margin-bottom: 3vw;
  }
  #article_sec1 .wrapper .content_right .content_category .content_category_list {
    display: flex;
    flex-wrap: wrap;
  }
  #article_sec1 .wrapper .content_right .content_category .content_category_list a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-size: 0.9rem;
    font-weight: bold;
    color: #ff6400;
    padding: 1vw 1.5vw;
    border: 1px solid #ff6400;
    border-radius: 14px;
    margin-right: 2vw;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 500px) {
  .visible-500 {
    display: block;
  }
  .hidden-500 {
    display: none;
  }
}
